import styled from "styled-components";
export const SmallLogo = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 15px;
  margin-top: 10px;
  cursor: pointer;
`;
export const Div = styled.div`
  background-color: #c2e7a9;
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;
`;

export const H1 = styled.li`
  color: #383789;
`;

export const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 17px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Li = styled.li`
  margin-right: 10px;
  color: #383789;
`;

export const LabName = styled.span`
  color: #383789;
  cursor: pointer;
  margin-right: 10px;
`;

export const Arrow = styled.img`
  width: 18px;
  height: 18px;
  vertical-align: middle;
`;
