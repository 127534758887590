import styled from "styled-components";

export const PaymentWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #666;
  & > button {
    width: 400px;
  }

  & div[role="radiogroup"] {
    width: 400px;
    margin: 0;
  }
`;

export const PaymentInfo = styled.div`
  width: 100%;
`;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin: 0 auto;
  margin-top: 20px;
`;

export const AccordionTitle = styled.button`
  width: 100%;
  background-color: #eee;
  color: #444;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background-color: #ccc;
  }
`;

export const AccordionContent = styled.div`
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
`;
export const P = styled.p`
  fontWeight: 600,
  fontSize: "14px"
`;

export const CheckboxWrapper = styled.div`
  width: 400px;
  margin: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
