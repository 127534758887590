import React, { Fragment, useState, useEffect } from "react";
import Header from "../header";
import { axiosConfig } from "../../utils/axiosConfig";
import moment from "moment";

import { H3, SampleContainer, Sample, P, List } from "./styles";
import { Loading } from "../Loading";
import { StyledLink } from "../StyledLink";

export function Samples(props) {
  const [sampleList, setSamples] = useState([]);
  const [loading, setLoading] = useState(false);
  let labinfo;
  if (localStorage.getItem("loginData") !== null) {
    labinfo = JSON.parse(window.localStorage.getItem("loginData")).lab;
  }

  async function getSamples() {
    if (!labinfo) {
      props.history.push("/");
    } else {
      try {
        setLoading(true);
        await axiosConfig.get("/sample/all").then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setSamples(res.data);
          } else if (res.status === 401 || res.status === 500) {
            window.localstorage.removeItem("loginData");
            props.history.push("/");
          }
        });
      } catch (e) {
        setLoading(false);
        props.history.push("/");
        window.localStorage.removeItem("loginData");
      }
    }
  }
  useEffect(() => {
    getSamples();
  }, []);
  const sampleItems = sampleList.map((sample) => (
    <Sample key={sample.sampleID}>
      <H3 style={{ marginBottom: "5px" }}>{sample.description}</H3>
      <P>Due Date {moment(sample.dueDate).format("YYYY/MM/DD")} 24:00 PST</P>
      <List>
        {labinfo.certificationMethod === "BOTH" ? (
          <>
            <StyledLink
              key={sample.sampleID + sample.description}
              to={{
                pathname: "/rma",
                sampleID: sample.sampleID,
                status: sample.status,
                certMethod: "WET",
                createdDate: sample.createdDate,
                dueDate: sample.dueDate,
                description: sample.description,
              }}
              style={{ color: "#383789", textDecoration: "none" }}
            >
              <li>Submit Analysis Results (WET)</li>
            </StyledLink>
            <StyledLink
              key={sample.sampleID}
              to={{
                pathname: "/rma",
                sampleID: sample.sampleID,
                status: sample.status,
                certMethod: "NIRS",
                createdDate: sample.createdDate,
                dueDate: sample.dueDate,
                description: sample.description,
              }}
              style={{ color: "#383789", textDecoration: "none" }}
            >
              <li>Submit Analysis Results (NIRS)</li>
            </StyledLink>
          </>
        ) : (
          <StyledLink
            key={sample.sampleID + sample.description}
            to={{
              pathname: "/rma",
              sampleID: sample.sampleID,
              status: sample.status,
              certMethod: labinfo.certificationMethod,
              createdDate: sample.createdDate,
              dueDate: sample.dueDate,
              description: sample.description,
            }}
            style={{ color: "#383789", textDecoration: "none" }}
          >
            <li>Submit Analysis Results ({labinfo.certificationMethod})</li>
          </StyledLink>
        )}
      </List>
    </Sample>
  ));

  return (
    <Fragment>
      <Header />
      {loading ? <Loading /> : null}
      <SampleContainer>
        {sampleList.length !== 0 ? (
          sampleItems
        ) : (
          <h1 style={{ margin: "auto" }}>Samples will be shipped soon</h1>
        )}
      </SampleContainer>
    </Fragment>
  );
}
