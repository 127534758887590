import React from "react";
import { Routes } from "./routes";
import styled from "styled-components";

const Div = styled(Routes)`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
`;

function App() {
  return <Div />;
}

export default App;
