import React, { Fragment, useState, useEffect } from "react";
import Header from "../header";
import { LabWrapper } from "./styles";

import LabInfoForm from "./LabInfoForm";
import { LabInfoHeader } from "./labInfoHeader";
import LabSampleForm from "./labSampleForm";
import { axiosConfig } from "../../utils/axiosConfig";

function Laboratory(props) {
  const [message, setMessage] = useState(null);
  let [labData, setData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const { labID } = props.match.params;
  let { lab } = props.location.state;

  let isAdmin = false;

  if (
    JSON.parse(window.localStorage.getItem("loginData")).user.role === "ADMIN"
  ) {
    isAdmin = true;
  }
  function handleChange(evt) {
    const {
      target: { name, value }
    } = evt;

    labData[name] = value;
  }

  async function updateLab() {
    if (isAdmin) {
      setLoading(() => true);
      await axiosConfig
        .post(`/lab/${labID}`, labData)
        .then(res => {
          setLoading(() => false);
          if (res.status === 200) {
            lab = res.data;
            setMessage("Lab info updated successfully");
          } else setMessage(res.data.message);
        })
        .catch(e => {
          setLoading(() => false);
          setMessage(e.response.data);
        });
    }
  }

  useEffect(() => {
    async function getLab() {
      await axiosConfig
        .get(`/lab/${labID}`)
        .then(res => {
          setLoading(() => false);
          if (res.status === 200) {
            setData(() => res.data);
          }
        })
        .catch(e => {
          setLoading(() => false);
          console.error(e);
        });
    }
    getLab();
    // setData(() => labData);
  }, []);

  return (
    <Fragment>
      <Header />
      <LabInfoHeader lab={labData} handleChange={handleChange} />
      <hr />
      <LabWrapper>
        <LabSampleForm lab={labData}/>
        <LabInfoForm
          handleChange={handleChange}
          labID={labID}
          isLoading={isLoading}
          lab={labData}
          updateLab={updateLab}
          message={message}
        />
      </LabWrapper>
    </Fragment>
  );
}

export default Laboratory;
