import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 362px;
`;

export const Ol = styled.ol`
  font-size: 14px;
  color: #666;

  & > li {
    position: relative;
    padding-left: 35px;
  }
  & > li:before {
    position: absolute;
    left: -5px;
  }
`;

export const H1 = styled.h1`
  color: #666;
  margin: 0;
  margin-top: 26px;
  font-size: 24px;
  font-weight: bold;
`;
export const H2 = styled.h2`
  font-size: 16px;
  color: #666;
  margin: 0;
  margin-bottom: 11px;
  font-weight: bold;
`;

export const Section = styled.section`
  color: #666;
  font-size: 14px;
`;
