import React, { useState, useRef, useEffect } from "react";
import logo from "../small-logo.png";
import styled from "styled-components";
import { Div, SmallLogo, LabName, Ul, H1, Arrow } from "./styles";
import { withRouter } from "react-router-dom";
import { axiosConfig } from "../../utils/axiosConfig";
import arrow from "../../arrow.svg";
import { StyledLink } from "../StyledLink";
import { Loading } from "../Loading";

const AccordionContent = styled.div`
  background-color: none;
  overflow: hidden;
  transition: max-height 0.6s ease;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  a {
    margin-top: 10px;
  }
`;

function LabHeader(props) {
  let [isLoading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const contentRef = useRef(null);

  const userData = JSON.parse(window.localStorage.getItem("loginData"));

  async function logout() {
    setLoading(() => true);
    await axiosConfig
      .post("/user/logout")
      .then(() => {
        window.localStorage.removeItem("loginData");
        setLoading(() => false);
        props.history.push("/");
      })
      .catch(e => {
        setLoading(() => false);
      });
  }

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, active]);

  const toogleActive = () => {
    setActive(!active);
  };

  function goHome() {
    props.history.push("/");
  }

  return (
    <React.Fragment>
      <Div>
        <SmallLogo onClick={goHome} src={logo} alt="logo" />
        <div>
          <Ul>
            <LabName>
              <StyledLink to="/questionnaire">Questionnaire</StyledLink>
            </LabName>
            <LabName>
              <StyledLink to="/samples">Samples</StyledLink>
            </LabName>
            <LabName>
              <StyledLink to="/payment">Payment</StyledLink>
            </LabName>
            <LabName>
              <StyledLink to="/reports">Reports</StyledLink>
            </LabName>
            <LabName
              className={active ? "accordion-icon rotate" : "accordion-icon"}
              onClick={toogleActive}
            >
              <H1>
                {userData.lab.companyName}
                <Arrow src={arrow} alt="arrow" />
              </H1>
              <AccordionContent ref={contentRef} className="accordion-content">
                <StyledLink to="/labinfo">Lab info</StyledLink>
                <StyledLink to="/" onClick={logout}>
                  Logout
                </StyledLink>
              </AccordionContent>
            </LabName>
          </Ul>
        </div>
      </Div>
      {isLoading ? <Loading /> : null}
    </React.Fragment>
  );
}

export default withRouter(LabHeader);
