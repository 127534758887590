import React from "react";
import MaterialTable from "material-table";

const columns = [
  {
    title: "President Name",
    field: "name",
  },
  {
    title: "Year",
    field: "year",
  },
];

export default function Presidents({
  presidents,
  addPresident,
  editPresident,
  deletePresident,
  loading,
}) {
  const [data, setData] = React.useState(presidents);

  React.useEffect(() => {
    setData(presidents);
  }, [presidents]);

  return (
    <MaterialTable
      title={"Presidents"}
      columns={columns}
      isLoading={loading}
      data={Array.from(data)}
      style={{ width: "100%" }}
      options={{
        actionsColumnIndex: -1,
      }}
      editable={{
        onRowAdd: (newData) => addPresident({ ...newData, year: +newData.year }),
        // onRowUpdate: (newData, oldData) =>
        //   new Promise((resolve) => {
        //     setTimeout(() => {
        //       editPresident({ ...newData, year: +newData.year }, oldData);
        //       resolve();
        //     }, 1000);
        //   }),
        onRowDelete: (oldData) => deletePresident(oldData),
      }}
    />
  );
}
