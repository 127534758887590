import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

function LabSelect({ onClose, open, labs, selectedValue }) {
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        Select lab you want to sign in
      </DialogTitle>
      <List>
        {labs.map(lab => (
          <ListItem
            button
            onClick={() => handleListItemClick(lab)}
            key={lab.labID}
          >
            <ListItemAvatar>
              <p>#{lab.labID}</p>
            </ListItemAvatar>
            <ListItemText primary={lab.companyName} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default LabSelect;
