import styled from "styled-components";

const Button = styled.button`
  margin: 0;
  padding: 0;
  background-color: #383789;
  width: 100%;
  height: 62px;
  border-radius: 8px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  &:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    color: #383789;

    height: 62px;
  }
`;

export default Button;
