import { axiosConfig } from "../../utils/axiosConfig";


export const signUpLab = async values => {
  const body = JSON.stringify({
    labName: values.labName,
    contactPerson: values.contactPerson,
    certificationMethod:
      values.labType.length === 2 ? "BOTH" : values.labType[0].toString(),
    email: values.email,
    password: values.password,
    address: values.address,
    state: values.state,
    zip: values.zip,
    country: values.country,
    city: values.city,
    phone: values.phone,
    address1: values.address1,
    address2: values.address2
  });
  return await axiosConfig.post("/user/signup", body);
};
