import React from "react";
import { Info, P } from "./styles";
import moment from "moment";

function QuestionnaireInfo({ certMethod, createdDate, dueDate, description }) {
  return (
    <Info>
      <P>Description</P>
      <P>{description}</P>
      <P>Created Date</P>
      <P>{moment(createdDate).format("YYYY/MM/DD")}</P>
      <P>Due Date</P>
      <P>{moment(dueDate).format("YYYY/MM/DD")}</P>
      <P>Methodology</P>
      {certMethod && certMethod.length > 0 ? (
        <P>{certMethod}</P>
      ) : (
        <P>Please select from list</P>
      )}
    </Info>
  );
}

export default QuestionnaireInfo;
