import styled from "styled-components";

export const LabWrapper = styled.div`
  display: grid;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  margin-top: 20px;
  margin-bottom: 50px;

  // & > :nth-child(2) {
  //   background-color: gray;
  // }
`;

export const Filler = styled.div`
  width: 100%;
  height: 100%;
  background-color: red;
  border: 5px solid black;
`;

export const InfoWrapper = styled.div`
  & > form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
  }
`;

export const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const H2 = styled.h1`
  font-size: 20px;
  font-weight: 900;
`;

export const Select = styled.select`
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  width: 100%;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  width: 80%;
  padding: 0;
  margin: 0 auto;
  margin-top: 20px;
`;

export const H1 = styled.h1`
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: #383789;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Arrow = styled.img`
  width: 18px;
  height: 18px;
  vertical-align: middle;
`;

export const Span = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  & * {
    margin: 0;
    padding: 0;
  }
`;

export const Accordion = styled.div`
  border: 1px solid gray;
  border-top: none;
  opacity: ${props => (props.show ? "1" : "0")};
  max-height: ${props => (props.show ? "100%" : "0")};
  
  width: ${props => (props.show ? "100px" : "0px")}
  padding: ${props => (props.show ? "15px" : "0 15px")};
  transition: max-height 0.5s ease;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f6f6f6;
  overflow: hidden;
  position: absolute;

  & * {
    overflow: hidden;
    transition: max-height 0.5s ease;
  
  }
`;

export const P = styled.p`
  font-size: 16px;
  color: #666;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const LabId = styled.h1`
  font-size: 16px;
  margin: 0;
  color: #383789;
`;

export const LabName = styled.h1`
  font-size: 24px;
  color: #666;
  font-weight: bold;
  margin: 0;
`;
