import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "ProximaNova";
  font-style: normal;
  font-weight: 400;
  src: url("../ProximaNova-Regular.otf");
} 
@font-face {
  font-family: "ProximaNova-Bold";
  font-style: normal;
  font-weight: 900;
  src: url("../ProximaNova-Bold.otf");
} 
body {
    font-family: "ProximaNova";
    font-style: normal;
    font-weight: 400;
  
    padding: 0;
    margin: 0;
    height: 100%;
  }

  button { outline: none; }

  a {
    text-decoration: none;

    &:visited {
      color: inherit;
    }
  }
`;

export default GlobalStyle;
