import React from "react";
import styled from "styled-components";

const Logo = styled.img`
  height: 215px;
  width: 215px;
`;

function Img(props) {
  return <Logo className={props.className} src={props.src} alt={props.alt} />;
}

export default Img;
