import React, { useEffect, useState } from "react";
import Header from "../header";
import {
  LabsWrapper,
  LabWrapper,
  Name,
  Lab,
  Logo,
  Logos,
  LabId,
  HeaderTitle,
  AddLabText,
  InputLine,
} from "./styles";
import { Input } from "../Input";
import { axiosConfig } from "../../utils/axiosConfig";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Loading } from "../Loading";
import Pagination from "./Pagination";
import { ProtectedRoute } from "../routes/ProtectedRoute";
import { StyledLink } from "../StyledLink";

//dropdown lab export
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// icons
import pipette from "../../pipette.svg";
import dollar from "../../dollar.svg";
import dollarblue from "../../dollarblue.svg";

import LabInfoPage from "./LabInfoPage";

import {
  ActiveLabs,
  InactiveLabs,
  AllLabs,
  LabsWithSubmissions,
} from "./ExcelExport.js";

import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { SvgIcon } from "@material-ui/core";

const iconColor = (lab) =>
  lab.policyAcceptedDate !== null ? "#383789" : "#CCCCCC";

function Laboratories(props) {
  const [labs, setLabs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [labsPerPage] = useState(20);
  const [filteredLabs, setFilteredLabs] = useState(labs);
  const [activeLabs, setActiveLabs] = useState([]);
  const [inactiveLabs, setInactiveLabs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [columnNames, setColumnNames] = useState([]);
  const [labsWithSubmissions, setLabsWithSubmissions] = useState([]);

  const indexOfLastLab = currentPage * labsPerPage;
  const indexOfFirstLab = indexOfLastLab - labsPerPage;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const fetchData = async () => {
    setLoading(true);
    axiosConfig
      .get("/lab/all")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setLabs(res.data);
          setFilteredLabs(() => res.data);
          getLabsWithSubmissions(res.data);
          setActiveLabs(res.data.filter((q) => q.paymentMethod !== "NONE"));
          setInactiveLabs(res.data.filter((q) => q.paymentMethod === "NONE"));
        } else if (res.status === 401 || res.status === 500) {
          window.localStorage.removeItem("loginData");
          props.history.push("/");
        }
      })
      .catch(() => {
        props.history.push("/");
        window.localStorage.removeItem("loginData");
      });
  };

  const getLabsWithSubmissions = async (labsResponse = []) => {
    setLoading(true);
    axiosConfig
      .get("/lab/sampleSubmission")
      .then((res) => {
        if (res.status === 200) {
          setColumnNames(res.data.headers);
          const labsArray = Object.keys(res.data.values).map((key) => {
            let ar = res.data.values[key];
            ar.labID = +key;
            return ar;
          });
          let merged = [];
          for (let i = 0; i < labsArray.length; i++) {
            merged.push({
              ...labsArray[i],
              ...labsResponse.find(
                (itmInner) => itmInner.labID === labsArray[i].labID
              ),
            });
          }
          setLabsWithSubmissions(merged);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        props.history.push("/");
        window.localStorage.removeItem("loginData");
      });
  };

  const searchLab = (e) => {
    let name = e.target.value;
    setSearchQuery(name);
    setFilteredLabs(() =>
      labs.filter((lab) =>
        lab.companyName.toLowerCase().includes(name.toLowerCase())
      )
    );
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Header />
      <LabsWrapper>
        {isLoading ? <Loading /> : null}
        <HeaderTitle>Laboratories</HeaderTitle>
        <InputLine>
          <Input
            type="text"
            placeholder="Search"
            onChange={(e) => searchLab(e)}
            value={searchQuery}
          />
          <AddLabText>
            <Button
              aria-controls="excel-menu"
              aria-haspopup="true"
              onClick={handleClick}
              style={{ color: "#383789" }}
            >
              <GetAppIcon
                style={{
                  width: "20px",
                  height: "20px",
                  verticalAlign: "middle",
                  marginBottom: "5px",
                  color: "#383789",
                }}
              />
              Export Labs
            </Button>
            <Menu
              id="excel-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
            >
              <MenuItem style={{ color: "#383789" }} onClick={handleClose}>
                <ActiveLabs dataset={activeLabs} />
              </MenuItem>
              <MenuItem style={{ color: "#383789" }} onClick={handleClose}>
                <InactiveLabs dataset={inactiveLabs} />
              </MenuItem>
              <MenuItem style={{ color: "#383789" }} onClick={handleClose}>
                <AllLabs dataset={labs} />
              </MenuItem>
              <MenuItem style={{ color: "#383789" }} onClick={handleClose}>
                <LabsWithSubmissions
                  dataset={labsWithSubmissions}
                  columns={columnNames}
                />
              </MenuItem>
            </Menu>

            <StyledLink to="/addlab">+ Add Lab</StyledLink>
          </AddLabText>
        </InputLine>
        <LabWrapper>
          {filteredLabs.slice(indexOfFirstLab, indexOfLastLab).map((lab) => (
            <Lab key={lab.labID}>
              <StyledLink
                key={lab.labID}
                to={{
                  pathname: `/laboratories/${lab.labID}`,
                  state: {
                    lab,
                  },
                }}
                params={{
                  labID: lab.labID,
                }}
              >
                <LabId>
                  #{lab.labID} {lab.companyName}
                </LabId>
              </StyledLink>
              <Name>
                <p>{lab.certificationMethod}</p>
                <p>
                  {lab.city}, {lab.state}
                </p>
              </Name>
              <Logos>
                <SvgIcon
                  style={{ fontSize: 30, color: iconColor(lab) }}
                  component={AssignmentTurnedInIcon}
                />
                <Logo
                  src={lab.paymentMethod === "NONE" ? dollar : dollarblue}
                  alt="dollar"
                />
                <Logo src={pipette} alt="pipette" />
              </Logos>
            </Lab>
          ))}
        </LabWrapper>
      </LabsWrapper>
      <Pagination
        labsPerPage={labsPerPage}
        totalLabs={filteredLabs.length}
        paginate={paginate}
      />
      <ProtectedRoute exact path={"lab/:labID"} component={LabInfoPage} />
    </React.Fragment>
  );
}

export default Laboratories;
