import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import PaymentForm from "./PaymentForm";
import Button from "../Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Checkbox from "@material-ui/core/Checkbox";

import { axiosConfig } from "../../utils/axiosConfig.js";
import { Loading } from "../Loading";
import Header from "../header";

import { PaymentWrapper, PaymentInfo, CheckboxWrapper } from "./styles";

import { withRouter } from "react-router-dom";

import CountryDialog from "./Dialog.js";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(3),
    width: "400px"
  }
}));

function AllPaid() {
  return <h1>We've received your payments. Thanks!</h1>;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Payment(props) {
  const [isLoading, setLoading] = useState(false);
  const [price, setPrice] = useState({});
  const [total, setTotal] = useState(0);
  const [value, setValue] = useState("card");
  const [message, setMessage] = useState("");
  const [shipping, setShipping] = useState(false);
  const [express, setExpress] = useState(false);
  const [color, setColor] = useState("success");
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);

  function setCountry() {
    props.history.push("/labinfo");
  }

  async function getPrice() {
    setLoading(true);

    await axiosConfig.get("/lab").then(res => {
      if (res.status === 200) {
        if (res.data.lab.country.length === 0 || !res.data.lab.country.trim()) {
          setDialogOpen(true);
        }
      } else if (
        res.status === 401 ||
        res.status === 400 ||
        res.status === 500
      ) {
        window.localStorage.removeItem("loginData");
        props.history.push("/");
      }
    });

    await axiosConfig
      .get("/lab/price")
      .then(res => {
        if (res.status === 200) {
          setLoading(() => false);
          setPrice(res.data);
          setTotal(res.data.price + res.data.shipping);
          if (res.data.shipping !== 0) {
            setShipping(true);
          }
        } else if (res.status === 401) {
          window.localStorage.removeItem("loginData");
          props.history.push("/");
        }
      })
      .catch(e => console.error(e));
  }

  useEffect(() => {
    getPrice();
  }, []);

  function handleSubmit() {
    setOpen(true);
    setMessage(
      "Make sure to pay it later using payment method you have chosen. You can always access this data in payment tab."
    );
    props.history.push("/samples");
  }

  async function handleToken(token) {
    setLoading(() => true);
    await axiosConfig
      .post("user/payment", null, {
        params: {
          token: token.id,
          email: token.email,
          shipping,
          express
        }
      })
      .then(res => {
        setLoading(() => false);
        if (res.status === 200) {
          setColor("success");
          setOpen(true);
          setMessage(
            "Payment successfully received. You will be redirected in 3 seconds."
          );

          setTimeout(function() {
            props.history.push("/samples");
          }, 3000);
        } else if (res.status === 400 || res.status === 500) {
          setColor("error");
          setOpen(true);
          setMessage(res.data.message);
        }
      })
      .catch(() => {
        setLoading(() => false);
        setOpen(true);
        setMessage("oops something went wrong");
      });
  }

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handlePriceChange = event => {
    if (event.target.checked) {
      setTotal(total + price[event.target.name]);
    } else if (!event.target.checked) {
      setTotal(total - price[event.target.name]);
    }

    if (event.target.name === "shipping") {
      setShipping(event.target.checked);
    } else if (event.target.name === "express") {
      setShipping(true);
      setExpress(event.target.checked);
    }
  };

  return (
    <>
      <Header />
      {isLoading ? (
        <Loading />
      ) : price.shipping === 0 && price.price === 0 && price.express === 0 ? (
        <PaymentWrapper>
          <AllPaid />
        </PaymentWrapper>
      ) : (
        <PaymentWrapper>
          <CheckboxWrapper>
            {price.shipping === 0 ? null : (
              <label>
                <Checkbox
                  style={{ padding: "0" }}
                  type="checkbox"
                  name="shipping"
                  onChange={handlePriceChange}
                  checked={price.shipping > 0}
                  value={price.shipping > 0}
                  color="primary"
                  disabled
                />
                Non-US facility add shipping/handling fee: ${price.shipping}
              </label>
            )}
            {price.express === 0 ? null : (
              <label>
                <Checkbox
                  style={{ padding: "0" }}
                  type="checkbox"
                  name="express"
                  onChange={handlePriceChange}
                  value={price.express > 0}
                  color="primary"
                />
                Non-US facility optional express shipping(must also pay shipping
                fee above): ${price.express}
              </label>
            )}
          </CheckboxWrapper>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              Select your payment method
              <br />
              Total to pay: ${total}
            </FormLabel>
            <RadioGroup
              aria-label="paymentMethod"
              name="paymentMethod"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="card"
                control={<Radio color="primary" />}
                label="Card"
              />
              {value === "card" ? (
                <PaymentForm handleToken={handleToken} />
              ) : null}
              <FormControlLabel
                value="wireTransfer"
                control={<Radio color="primary" />}
                label="Wire Transfer"
              />
              {value === "wireTransfer" ? (
                <PaymentInfo>
                  International Wire Transfers in US Dollars to UBT account:{" "}
                  <br />
                  WELLS FARGO
                  <br /> SAN FRANCISCO CA <br />
                  SWIFT ADDRESS: WFBIUS6S <br />
                  ABA: 121000248
                  <br />
                  FOR FURTHER CREDIT:
                  <br /> BNF FI: <br />
                  UNION BK & TRUST <br />
                  4732 CALVERT LINCOLN NE 68506 <br />
                  ABA #: 104910795 (ROUTING NUMBER) <br />
                  ACCT # 115-009-7551
                  <br /> FOR FINAL CREDIT
                  <br /> BNF ACCT NAME: NATIONAL FORAGE TESTING ASSOCIATION{" "}
                  <br />
                  BNF ACCT #: 2094-3316
                </PaymentInfo>
              ) : null}

              <FormControlLabel
                value="ach"
                control={<Radio color="primary" />}
                label="ACH"
              />

              {value === "ach" ? (
                <PaymentInfo>
                  Routing Number: 104910795 <br /> Account Number: 20943316
                </PaymentInfo>
              ) : null}

              <FormControlLabel
                value="check"
                control={<Radio color="primary" />}
                label="Check"
              />
              {value === "check" ? (
                <PaymentInfo>
                  Checks should be sent to NFTA, PO Box 1470, Stuart, FL 34995.
                </PaymentInfo>
              ) : null}
            </RadioGroup>

            {value === "card" ? null : (
              <p>Once payment is received admin will make your lab active.</p>
            )}
          </FormControl>

          {value === "card" ? null : (
            <Button onClick={handleSubmit} type="button">
              I will pay by this method
            </Button>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={6000}
          >
            <Alert color={color}>{message}</Alert>
          </Snackbar>
          <CountryDialog open={dialogOpen} setCountry={setCountry} />
        </PaymentWrapper>
      )}
    </>
  );
}

export default withRouter(Payment);
