import React from "react";
import "react-toastify/dist/ReactToastify.css";
import StripeCheckout from "react-stripe-checkout";

function PaymentForm(props) {
  return (
    <React.Fragment>
      <StripeCheckout
        stripeKey={process.env.REACT_APP_STRIPE_KEY}
        token={props.handleToken}
      />
    </React.Fragment>
  );
}

export default PaymentForm;
