import React, { useState } from "react";
import { Formik, Field } from "formik";

import Form from "../Form";
import Button from "../Button";
import Label from "../Label";
import { Checkbox } from "../Input";
import { withRouter } from "react-router-dom";
import { agreementValidation } from "../../utils/validationSchemas";
import { Loading } from "../Loading";
import { axiosConfig } from "../../utils/axiosConfig";

const initialValues = {
  name: "",
  jobTitle: "",
  isAgreedToEthics: false
};

function AgreementForm(props) {
  let [isLoading, setLoading] = useState(false);
  let [message, setMessage] = useState("");

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={agreementValidation}
      isInitialValid={false}
      onSubmit={async (values, { setSubmitting }) => {
        setLoading(() => true);
        await axiosConfig
          .post("/user/acceptPolicy", null, {
            params: {
              name: values.name,
              jobTitle: values.jobTitle
            }
          })
          .then(res => {
            setLoading(() => false);
            if (res.status === 200) {
              props.history.push("/payment");
            } else if (res.status === 400) {
              setMessage(res.data.message);
            }
          });

        setSubmitting(() => false);
      }}
    >
      {({ handleChange, handleBlur, handleSubmit, isValid, values }) => (
        <Form className="login-form" onSubmit={handleSubmit}>
          {isLoading ? <Loading /> : null}
          <div className="login-form__field">
            <Field
              type="text"
              name="name"
              placeholder="Name *"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="login-form__field">
            <Field
              type="text"
              name="jobTitle"
              placeholder="Job Title *"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <Label>
            <Checkbox
              type="checkbox"
              name="isAgreedToEthics"
              value={values.isAgreedToEthics}
              checked={values.isAgreedToEthics}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            I have read and agree with the above statements
          </Label>

          {message.length !== 0 ? message : null}
          <Button type="submit" disabled={!isValid} onChange={handleChange}>
            Agree to Ethics
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default withRouter(AgreementForm);
