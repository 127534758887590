import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import styled from "styled-components";

const LoadingPage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(246, 246, 246, 0.5);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  text-align: center;

  & > p {
    font-size: 48px;
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    width: "100vw",
    height: "100vh",
    marginTop: "4px",
    borderRadius: "8px",
    marginBottom: "4px",
    "& > * + *": {
      marginTop: "4px",
      borderRadius: "8px",
      marginBottom: "4px"
    }
  }
}));

export function Loading() {
  const classes = useStyles();
  return (
    <LoadingPage>
      <div className={classes.root}>
        <LinearProgress />
      </div>
    </LoadingPage>
  );
}
