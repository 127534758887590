import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";

import { makeStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    fontSize: "16px",
    marginLeft: "4%",
    marginRight: "4%",
    marginTop: "1.5rem"
  }
});

function LabSelect(props) {
  const { onClose, open, emails, selectedValue } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  const proceedToHomePage = () => {
    props.history.push("/samples");
  };
  const classes = useStyles();
  return (
    <Dialog onClose={handleClose} open={open}>
      <ListItemText className={classes.list}>
        Please, select one managing email address. Only chosen one will have
        access to laboratory.
      </ListItemText>
      <List>
        {emails.map((email, idx) => (
          <ListItem button onClick={() => handleListItemClick(email)} key={idx}>
            <DialogTitle>{email}</DialogTitle>
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button onClick={proceedToHomePage} color="primary" autoFocus>
          Skip
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(LabSelect);
