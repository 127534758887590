import React, { useState } from "react";
import { Wrapper, Button, Input } from "./styles";
import Header from "../header";

import { axiosConfig } from "../../utils/axiosConfig";


export const getToken = async email => {
  return await axiosConfig.post("/user/passwordResetRequest", null, {
    params: {
      email: email
    }
  });
};
export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  function handleChange(e) {
    const { value } = e.target;
    setEmail(value);
  }

  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <h1>Forgot your password?</h1>
        <Input
          name="email"
          type="text"
          onChange={handleChange}
          placeholder="Enter your email to reset password"
        />
        <Button onClick={getToken(email)}>Reset password</Button>
      </Wrapper>
    </React.Fragment>
  );
};
