import React, { useState } from "react";
import { Formik } from "formik";
import logo from "../logo.png";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import { loginValidationSchema } from "../../utils/validationSchemas";
import "./style.css";
import Img from "../logo";
import Button from "../Button";
import { Input } from "../Input";
import { FormContainer } from "../FormContainer";
import { Ul } from "./styles";
import { ErrorMessage } from "../errorMessage";

import { axiosConfig } from "../../utils/axiosConfig";

import { Loading } from "../Loading";
import moment from "moment";
import LabSelect from "./LabSelect.js";
import EmailSelect from "./EmailSelect.js";
import ErrorDialog from "./Dialog.js";

function Login(props) {
  const labRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [errorMessage, setErrorMeessage] = useState("");
  const [isForgotPassword, setisForgotPassword] = useState(false);
  let [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [emailOpen, setEmailOpen] = useState(false);
  const [labList, setLabList] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [user, setUser] = useState("");

  function openModal() {
    setOpen(true);
  }

  function checkPolicyDate() {
    const currentUser = JSON.parse(localStorage.getItem("loginData")).user;
    const thisYear = new Date().getFullYear();
    let policyAcceptedDate;
    if (currentUser.policyAcceptedDate !== null) {
      policyAcceptedDate = moment(
        currentUser.policyAcceptedDate.split(" ")[0]
      ).format("MMMM D YYYY");
      if (moment(policyAcceptedDate).isValid()) {
        if (
          moment(policyAcceptedDate).isBefore(
            moment(thisYear + "-01-01").format("MMMM D YYYY")
          )
        ) {
          props.history.push("/agreement");
        } else {
          props.history.push("/samples");
        }
      }
    } else {
      props.history.push("/agreement");
    }
  }

  const handleEmailClose = async (email) => {
    await axiosConfig
      .post("/user/managerEmail", null, {
        params: {
          email,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setEmailOpen(false);
          checkPolicyDate();
        }
      });
  };

  const handleClose = async (lab) => {
    await axiosConfig
      .post("/user/setCurrentLab", null, {
        params: {
          labID: lab.labID,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          window.localStorage.setItem(
            "loginData",
            JSON.stringify({
              lab: lab,
              user,
            })
          );
          checkPolicyDate();
        }
      });
    setOpen(false);
    setSelectedValue(lab.labID);
  };

  async function togglePasswordForgot(values) {
    setisForgotPassword(!isForgotPassword);
    if (values.labId && values.labId.match(labRegex)) {
      setLoading(() => (isLoading = true));
      isLoading = true;
      await axiosConfig
        .post("/user/passwordResetRequest", null, {
          params: {
            email: values.labId,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            setLoading(() => false);
            setMessage("Password reset token was sent to your email");
          } else if (res.status === 404 || res.status === 401) {
            setLoading(() => false);
            setMessage(res.data.message);
          }
        })
        .catch(error => {
          if (error.status === 400) {
            setErrorMeessage(error.data.message);
            setDialogOpen(true);
          }
       });;

      setLoading(() => false);

      setisForgotPassword(!isForgotPassword);
    } else {
      setMessage("Please enter valid email");
    }
  }

  return (
    <FormContainer>
      {isLoading ? <Loading /> : null}
      <Img className="logo" src={logo} alt="logo" />
      <Formik
        initialValues={{ labId: "", password: "" }}
        onSubmit={async (values, { setSubmitting }) => {
          const options = {
            headers: {
              email: values.labId,
              password: values.password,
            },
          };
          setLoading(() => true);
          await axiosConfig
            .post("/user/login", null, options)
            .then((res) => {
              if (res.status === 200) {
                // if admin send to labs page
                if (
                  res.data.lab === undefined &&
                  res.data.user.role === "ADMIN"
                ) {
                  window.localStorage.setItem(
                    "loginData",
                    JSON.stringify(res.data)
                  );
                  props.history.push("/laboratories");
                }
                // if email has many labs
                else if (res.data.user.email.split(";").length > 1) {
                  window.localStorage.setItem(
                    "loginData",
                    JSON.stringify({
                      lab: res.data.lab[0],
                      user: res.data.user,
                    })
                  );

                  setEmailList(() => res.data.user.email.split(";"));
                  setEmailOpen(() => true);
                  setEmailOpen(true);
                } else if (res.data.lab.length > 1) {
                  setUser(res.data.user);
                  setLabList(() => res.data.lab);
                  openModal();
                }
                // only one lab is signed up, we are safe to sign in
                else {
                  window.localStorage.setItem(
                    "loginData",
                    JSON.stringify({
                      lab: res.data.lab[0],
                      user: res.data.user,
                    })
                  );
                  // if policy wasn't accepted this year, then redirect to agreement
                  checkPolicyDate();
                }
              } else if (res.status === 401) {
                setLoading(() => false);

                setMessage(() => res.data.message);
              }
            })
            .catch((e) => {
              setLoading(false);
              setMessage(e.data.message);
            });

          setLoading(() => false);
          return () => {
            setSubmitting(false);
          };
        }}
        validationSchema={loginValidationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors,
          touched,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Input
              type="text"
              name="labId"
              placeholder="Lab email *"
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {errors.labId && touched.labId ? (
              <ErrorMessage>{errors.labId}</ErrorMessage>
            ) : (
              ""
            )}

            <Input
              type="password"
              name="password"
              placeholder="Password *"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.password && touched.password && !isForgotPassword ? (
              <ErrorMessage>{errors.password}</ErrorMessage>
            ) : (
              ""
            )}
            {message.length > 0 ? <ErrorMessage>{message}</ErrorMessage> : null}
            <Button
              className="login-form__button"
              type="submit"
              disabled={
                !isSubmitting && !errors.password && errors.labId && touched
              }
            >
              Sign in
            </Button>
            <Ul>
              <li onClick={() => togglePasswordForgot(values)}>
                Forgot password?
              </li>
              <li>
                <Link to="/signup">Don't have an account? Sign up</Link>
              </li>
            </Ul>
          </form>
        )}
      </Formik>
      <LabSelect
        open={open}
        onClose={handleClose}
        selectedValue={selectedValue}
        labs={labList}
        user={user}
      />
      <EmailSelect
        open={emailOpen}
        onClose={handleEmailClose}
        selectedValue={selectedValue}
        emails={emailList}
      />
      <ErrorDialog open={dialogOpen} message={errorMessage} cancel={() => setDialogOpen(false)} />
    </FormContainer>
  );
}

export default withRouter(Login);
