import React from "react";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  // TODO: Hook up isAuthenticated to the store or other indicator after login functionality is completed.
  if (
    window.localStorage.getItem("loginData") &&
    typeof window.localStorage.getItem("loginData") !== "undefined"
  ) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )}
      />
    );
  }
};

// const mapStateToProps = (state, ownProps) => ({
//   isAuthenticated: state.authStore.isAuthenticated
// });

// export default withRouter(connect(mapStateToProps)(ProtectedRoute));
