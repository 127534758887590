import React, { useState } from "react";
import { Formik, Form } from "formik";
import Select from "react-select";
import Checkbox from "@material-ui/core/Checkbox";
// Styles and other components
import logo from "../logo.png";
import { labCreationValidation } from "../../utils/validationSchemas";
import { ErrorMessage } from "../errorMessage";
import Img from "../logo";
import Button from "../Button";
import { Input } from "../Input";
import { FormContainer } from "../FormContainer";
import { Countries, States } from "../signup/countries";
import { H2, LabType, CheckboxContainer } from "../signup/styles";
import { Loading } from "../Loading";
import { axiosConfig } from "../../utils/axiosConfig";

import { Debug } from "../Lab/Debug";

import InputMask from "react-input-mask";

const CustomInput = props => (
  <InputMask {...props}>{inputProps => <Input {...inputProps} />}</InputMask>
);

const initialValues = {
  labName: "",
  contactPerson: "",
  phone: "",
  address1: "",
  address2: "",
  city: "",
  zip: "",
  email: "",
  state: "",
  country: "United States",
  certificationMethod: []
};

const customStyled = {
  container: provided => ({
    ...provided,
    width: "100%",
    marginBottom: "1rem"
  }),
  singleValue: provided => ({
    ...provided,
    color: "#000"
  }),
  placeholder: provided => ({
    ...provided,
    color: "#c4c4c4"
  }),
  menu: provided => ({
    ...provided,
    margin: 0,
    padding: 0
  }),
  menuList: provided => ({
    ...provided,
    margin: "0",
    padding: 0
  })
};
function AddLab(props) {
  let [isLoading, setLoading] = useState(false);
  let [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);

  return (
    <FormContainer>
      <Img className="logo" src={logo} alt="logo" />
      <H2>Account Creation</H2>
      <Formik
        initialValues={initialValues}
        validationSchema={labCreationValidation}
        isInitialValid={labCreationValidation.isValidSync(initialValues)}
        // isValid={signUpValidation.isValidSync(values)}
        onSubmit={async (values, { setSubmitting }) => {
          setLoading(() => true);
          if (values.certificationMethod.length > 1) {
            values.certificationMethod = "BOTH";
          } else {
            values.certificationMethod = values.certificationMethod[0];
          }

          await axiosConfig
            .post("/user/add", values)
            .then(res => {
              setLoading(() => false);
              if (res.status === 200) {
                setShow(() => true);
                setMessage(res.data.message);
                setTimeout(() => {
                  props.history.push("/laboratories");
                }, 3500);
              } else if (res.status === 400) {
                setMessage(res.data.message);
                setShow(() => true);
              }
            })
            .catch(e => console.error(e));
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isValid,
          isInitialValid,
          setFieldValue
        }) => (
          <Form style={{ height: "100%" }} onSubmit={handleSubmit}>
            {isLoading ? <Loading /> : null}
            <Input
              type="text"
              name="labName"
              placeholder="Lab Name *"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.labName}
            />
            {errors.labName && touched.labName ? (
              <ErrorMessage name="labName">{errors.labName}</ErrorMessage>
            ) : (
              ""
            )}

            <Input
              type="text"
              name="contactPerson"
              placeholder="Contact Person"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.contactPerson}
            />
            {errors.contactPerson && touched.contactPerson ? (
              <ErrorMessage>{errors.contactPerson}</ErrorMessage>
            ) : (
              ""
            )}

            <Input
              type="text"
              name="email"
              placeholder="Email Address"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {errors.email && touched.email ? (
              <ErrorMessage>{errors.email}</ErrorMessage>
            ) : (
              ""
            )}
            <CustomInput
              name="phone"
              placeholder="Phone number"
              onChange={e => {
                const phn = e.target.value.replace(/\D/g, "");
                setFieldValue("phone", phn);
              }}
              autoComplete="off"
              maskPlaceholder="1"
              mask="+9 (999) 999-99-99"
              value={values.phone}
            />
            <Input
              type="text"
              name="address1"
              placeholder="Address 1"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address1}
            />
            {errors.address1 && touched.address1 ? (
              <ErrorMessage>{errors.address1}</ErrorMessage>
            ) : (
              ""
            )}
            <Input
              type="text"
              name="address2"
              placeholder="Address 2"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address2}
            />
            <Input
              type="text"
              name="city"
              placeholder="City"
              onChange={handleChange}
              autoComplete="off"
              onBlur={handleBlur}
              value={values.city}
            />
            {errors.city && touched.city ? (
              <ErrorMessage>{errors.city}</ErrorMessage>
            ) : (
              ""
            )}
            {values.country === "United States" ? (
              <Select
                options={States}
                name="state"
                disabled={!values.country}
                placeholder={"State"}
                isSearchable={true}
                onBlur={handleBlur}
                styles={customStyled}
                value={
                  States
                    ? States.find(option => option.value === values.value)
                    : ""
                }
                onChange={selectedOption => {
                  values.state = selectedOption.value;
                }}
              />
            ) : (
              <Input
                type="text"
                name="state"
                placeholder="Province"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.state}
              />
            )}
            {(errors.state && touched.state) ||
            touched["react-select-3-input"] ? (
              <ErrorMessage>{errors.state}</ErrorMessage>
            ) : (
              ""
            )}

            <Input
              type="text"
              name="zip"
              placeholder="ZIP Code"
              onChange={handleChange}
              autoComplete="off"
              onBlur={handleBlur}
              value={values.zip}
            />
            {errors.zip && touched.zip ? (
              <ErrorMessage>{errors.zip}</ErrorMessage>
            ) : (
              ""
            )}
            <Select
              options={Countries}
              name="country"
              defaultValue={Countries[229]}
              isSearchable={true}
              styles={customStyled}
              value={
                Countries
                  ? Countries.find(option => option.value === Countries.value)
                  : "United States"
              }
              onChange={selectedOption => {
                values.country = selectedOption.value;
              }}
              onBlur={handleBlur}
            />
            {errors.country && touched.country ? (
              <ErrorMessage>{errors.country}</ErrorMessage>
            ) : (
              ""
            )}

            <CheckboxContainer>
              <LabType>
                Lab type
                <div>
                  <sub className="sub">Check all that apply</sub>
                </div>
              </LabType>
              <label>
                <Checkbox
                  type="checkbox"
                  name="certificationMethod"
                  value="NIRS"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="primary"
                  inputProps={{
                    "aria-label": "secondary checkbox"
                  }}
                />
                NIRS
              </label>
              <br />
              <label>
                <Checkbox
                  type="checkbox"
                  name="certificationMethod"
                  value="WET"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="primary"
                />
                Wet Chemistry
              </label>
            </CheckboxContainer>
            {errors.certificationMethod && touched.certificationMethod ? (
              <ErrorMessage>{errors.certificationMethod}</ErrorMessage>
            ) : (
              ""
            )}
            <span>{error}</span>
            {/* <Debug /> */}
            <span>{show ? message : null}</span>
            <Button type="submit" disabled={!isValid} onSubmit={handleSubmit}>
              Add Lab
            </Button>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

export default AddLab;
