import React, { useState } from "react";
import Select from "react-select";
import {
  LabInfoWrapper,
  FieldWrapper,
  CellWrapper,
  Input,
  FullInput
} from "../LabInfo/styles";
import Button from "../Button";
import { axiosConfig } from "../../utils/axiosConfig";


import { Loading } from "../Loading";
import { Countries, States } from "../signup/countries";

export default function LabInfoPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  let [isUS, setUS] = useState(false);
  let [labData, changeData] = useState({});

  function handleChange(evt) {
    const {
      target: { name, value }
    } = evt;
    labData[name] = value;
    changeData(() => labData);
  }
  async function updateLab() {
    setIsLoading(() => true);
    await axiosConfig
      .post("/lab", labData)
      .then(res => {
        setIsLoading(() => false);
        if (res.status === 200) {
          setMessage("Lab info updated successfully");
        } else setMessage(res.data.message);
      })
      .catch(e => {
        setIsLoading(() => false);
        setMessage(e.response.data);
      });
  }

  return (
    <LabInfoWrapper>
      {isLoading ? <Loading /> : null}
      <h2>Laboratory details</h2>
      <FieldWrapper>
        <CellWrapper>
          <p>Laboratory name</p>
          <FullInput
            name="companyName"
            placeholder={labData.companyName}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Address 1</p>
          <FullInput
            name="address"
            placeholder={labData.address}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Address 2</p>
          <FullInput
            name="address2"
            placeholder={labData.address2}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>City</p>
          <Input
            name="city"
            placeholder={labData.city}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
        <CellWrapper>
          <p>{isUS ? "State" : "Province"}</p>
          {isUS ? (
            <Select
              options={States}
              name="state"
              isSearchable={true}
              placeholder={labData.state}
              value={
                States
                  ? States.find(option => option.value === labData.state)
                  : ""
              }
              onChange={selectedOption => {
                labData.state = selectedOption.value;
              }}
            />
          ) : (
            <Input
              type="text"
              name="state"
              placeholder={labData.state}
              autoComplete="off"
              onChange={e => handleChange(e)}
              // value={labData.state}
            />
          )}
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Postal code</p>
          <Input
            name="zip"
            placeholder={labData.zip}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
        <CellWrapper>
          <p>Country</p>
          <Select
            options={Countries}
            name="country"
            isSearchable={true}
            placeholder={labData.country}
            value={
              Countries
                ? Countries.find(option => option.value === Countries.value)
                : "United States"
            }
            onChange={selectedOption => {
              labData.country = selectedOption.value;
            }}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Contact name </p>
          <FullInput
            name="contact"
            placeholder={labData.contact1}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Phone</p>
          <Input
            name="telephone"
            placeholder={labData.telephone}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
        <CellWrapper>
          <p>Fax</p>
          <Input
            name="fax"
            placeholder={labData.fax}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
      </FieldWrapper>
      {message}
      <Button onClick={updateLab}>Save changes</Button>
    </LabInfoWrapper>
  );
}
