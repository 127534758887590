import axios from "axios";
import { history } from "../index";

const API_URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_BASE_API_URL_PROD
    : process.env.REACT_APP_BASE_API_URL_DEV;

export const axiosConfig = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  },
  timeout: 30 * 1000,
});

axiosConfig.defaults.withCredentials = true;
axios.defaults.crossDomain = true;

axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      history.push("/");
      window.localStorage.removeItem("loginData");
    }

    return Promise.reject(error.response);
  }
);
