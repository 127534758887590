import styled from "styled-components";

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 350px;
  height: 20%;
  max-width: 30%;
  margin: auto;
  margin-top: 60px;
  background-color: #f6f6f6;
  /*f6f6f6;*/
`;

export const SampleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 16px;
  margin-right: 8px;
  margin-top: 24px;
`;

export const H3 = styled.div`
  font-family: ProximaNova;
  font-weight: bold;
  margin-top: 19px;
  margin-left: 10px;
  font-size: 24px;
  color: #666666;
`;

export const P = styled.div`
  font-size: 18px;
  color: #666666;
  margin-left: 10px;
`;

export const SampleItems = styled.div`
  width: 100%;
  height: 80%;

  &:last-child {
    margin-bottom: 50px;
  }
`;

export const SampleItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 16px;
  color: #383789;
  margin-left: 10px;
  &:first-child {
    margin-top: 20px;
  }
  &:after {
    margin-right: 10px;
    content: ">  ";
  }
`;

export const Sample = styled.div`
  flex: 0 23%;
  width: 350px;
  height: 200px;
  margin-bottom: 2%; /* (100-32*3)/2 */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 10px;
  background-color: #f6f6f6;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 10px;
  margin-top: 10px;

  & > a {
    cursor: pointer;
    margin-bottom: 10px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: calc(288px / 3);

  & > * {
    color: #383789;
  }
`;
