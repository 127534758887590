import React, { useState, useEffect } from "react";
import { Loading } from "../Loading";
import { axiosConfig } from "../../utils/axiosConfig";
import {
  QuestionnaireWrapper,
  Heading,
  ContentWrapper,
  QuestionTitle,
  Container,
  QuestionWrapper,
  QuestionsWrapper,
  Description,
  InputWrapper,
  StyledCheckbox,
} from "./styles";
import Header from "../header";
import QuestionnaireInfo from "./questionnaireInfo";
import Select from "react-select";

// material
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";

import SnackbarMessage from "../Snackbar";
import { Input } from "../Input";
import Button from "../Button";

export default function Questionnaire(props) {
  let labInfo;
  if (window.localStorage.getItem("loginData") !== null) {
    labInfo = JSON.parse(window.localStorage.getItem("loginData"));
  } else {
    props.loocation.push("/");
  }
  const { createdDate, dueDate, description } = props.location;
  const [questions, setQuestions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [alreadyCompleted, setAlreadyCompleted] = useState(false);
  const [results, setResults] = useState(new Map());
  const [valid, setValid] = useState(false);
  const [questionAmount, setQuestionAmount] = useState(0);
  const [certMethod, setCertMethod] = useState("");

  function handleChange(event) {
    const { checked, name, value } = event.target;
    document.getElementsByName(name + "")[0].style.color = "#666666";
    setResults(results.set(name, checked ? value : "undefined"));
    if (results.size === questionAmount) {
      setValid(true);
    }
    // if (surveyValues.has(name)) {

    //   surveyValues.delete(name);
    // } else {
    // }
  }

  function validateSurvey() {
    const questionsID = [];
    questions.map((section) => {
      section.questions.map((answers) => {
        questionsID.push(answers.questionID);
      });
    });
    const resultsIDS = [...results.keys()].map((x) => parseInt(x, 10));
    const difference = questionsID.filter((x) => resultsIDS.indexOf(x) === -1);
    difference.map((x) => {
      const unanswered = document.getElementsByName(x);
      unanswered[0].style.color = "#d32f2f";
    });

    document.getElementsByName(difference[0] + "")[0].scrollIntoView();
  }

  function handleName(e) {
    const n = e.target.value;
    setName(n);
  }

  function convertToMap(arr) {
    // let map = arr.reduce(function(map, obj) {
    //   map[obj.questionID] = obj.choiceID;
    //   return map;
    // }, {});
    for (let i = 0; i < arr.length; i++) {
      setResults(results.set(arr[i].questionID + "", arr[i].choiceID + ""));
    }
  }

  function JSONIFY() {
    let b = [];

    for (var [key, value] of results) {
      b.push({
        questionID: key,
        choiceID: value,
      });
    }
    return JSON.stringify(b);
  }

  async function handleSubmit() {
    if (valid && name) {
      setLoading(() => true);
      try {
        await axiosConfig
          .post("/survey", JSONIFY(), {
            params: {
              title: name,
            },
          })
          .then((res) => {
            setLoading(() => false);
            if (res.status === 200) {
              setSuccess(true);
              setMessage("Results has been successfully saved");
              setOpen(true);
            }
          });
      } catch (e) {
        setSuccess(false);
        setMessage(e.data.message);
        setLoading(() => false);
        setOpen(true);
      }
    } else {
      if (!name) {
        setMessage("Please Enter Your Name");
        setOpen(true);
      } else {
        setMessage("Please Answer All Questions");
        setOpen(true);
        validateSurvey();
      }
    }
  }

  function countQuestions(qs) {
    const questionsCount = qs.reduce(
      (acc, element) => acc + element.questions.length,
      0
    );
    if (alreadyCompleted) {
      questionsCount === results.size ? setValid(true) : setValid(false);
    }
    setQuestionAmount(questionsCount);
    return questionsCount;
  }

  function checkIfAnswered(res) {
    const mappable = [];
    res.map((question) => {
      question.questions.map((answers) => {
        answers.choices.map((answer) => {
          if (answer.selected === true) {
            mappable.push(answer);
            setAlreadyCompleted(true);
            return alreadyCompleted;
          }
        });
      });
    });
    convertToMap(mappable);
  }

  async function getQuestions(method) {
    setLoading(() => true);
    try {
      await axiosConfig.get("/survey/questions?type=" + method).then((res) => {
        if (res.status === 200) {
          checkIfAnswered(res.data);
          setQuestions(() => res.data);
          countQuestions(res.data);
          setLoading(() => false);
        }
      });
    } catch (e) {
      setLoading(() => false);

      props.history.push("/");
    }
  }

  function handleCertMethodChange(e) {
    setCertMethod(e.value);
    getQuestions(e.value);
  }

  const CertMethod = () => {
    const options = [
      {
        value: "NIRS",
        label: "NIRS",
      },
      { value: "WET", label: "WET" },
    ];
    return (
      <Select
        value={certMethod || "Select Certification Method"}
        onChange={handleCertMethodChange}
        options={options}
      />
    );
  };

  useEffect(() => {
    if (labInfo.lab.certificationMethod !== "BOTH") {
      setCertMethod(labInfo.lab.certificationMethod);
      getQuestions(labInfo.lab.certificationMethod);
    }
  }, []);

  return (
    <QuestionnaireWrapper>
      <Header />
      <ContentWrapper>
        <Heading>
          <h1>Questionnaire</h1>
          <h3>
            When you are finished posting results, you must scroll to the bottom
            of this form, type your name (sign), and press the Submit button.
            All errors must be corrected, and you must sign this form before
            your submit will be successful.
          </h3>
        </Heading>
        <Container>
          {isLoading ? <Loading /> : null}
          <QuestionnaireInfo
            description={description}
            createdDate={createdDate}
            dueDate={dueDate}
            certMethod={certMethod}
          />
          {questions.length <= 0 ? (
            <CertMethod />
          ) : (
            <>
              <FormControl component="fieldset">
                {questions.map((q) => (
                  <QuestionsWrapper key={q.sectionID}>
                    <QuestionTitle>{q.name}</QuestionTitle>
                    <Description>{q.description}</Description>
                    {q.questions.map((question, index) => (
                      <QuestionWrapper key={question.questionID}>
                        <QuestionTitle name={question.questionID}>
                          {index + 1}. {question.text}
                        </QuestionTitle>
                        <RadioGroup
                          aria-label="position"
                          key={question.questionID}
                          column="true"
                          name={question.questionID + ""}
                          defaultValue={results.get(question.questionID + "")}
                        >
                          {question.choices.map((choice) => {
                            return alreadyCompleted ? (
                              <StyledCheckbox
                                key={choice.choiceID}
                                control={<Radio color="primary" />}
                                onChange={handleChange}
                                name={question.questionID + ""}
                                value={choice.choiceID + ""}
                                label={choice.text}
                                labelPlacement="end"
                              />
                            ) : (
                              <StyledCheckbox
                                key={choice.choiceID}
                                control={<Radio color="primary" />}
                                label={choice.text}
                                labelPlacement="end"
                                onChange={handleChange}
                                name={question.questionID + ""}
                                value={choice.choiceID + ""}
                              />
                            );
                          })}
                        </RadioGroup>
                      </QuestionWrapper>
                    ))}
                  </QuestionsWrapper>
                ))}
              </FormControl>

              <InputWrapper>
                <Input
                  style={{ width: "400px", align: "center", margin: "auto" }}
                  type="text"
                  name={name}
                  placeholder="Name"
                  onChange={handleName}
                  required={true}
                />
                <Button color="primary" type="submit" onClick={handleSubmit}>
                  Send questionnaire results
                </Button>
              </InputWrapper>
            </>
          )}
          <SnackbarMessage
            variant={success ? "success" : "error"}
            message={message}
            onClose={() => setOpen(false)}
            open={open}
          />
        </Container>
      </ContentWrapper>
    </QuestionnaireWrapper>
  );
}
