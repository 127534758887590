import styled from "styled-components";

export const Input = styled.input`
  &,
  &:active,
  &:focus {
    box-sizing: border-box;
    width: 100%;
    height: 62px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 23px 10px;
    margin-bottom: 2rem;
  }

  &::placeholder {
    color: #c4c4c4;
  }
  & ~ select {
    width: 100%;
  }
`;

export const NumberInput = styled(Input)`
  -webkit-appearance: none;
  margin: 0;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Checkbox = styled.input`
  margin-right: 10px;
  margin-top: 10px;
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 8px;
  vertical-align: middle;
  border: 1px solid rgba(0, 0, 0, 0.23);
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  &:checked {
    background-color: gray;
  }
  &:last-child {
    margin-bottom: 10px;
  }
`;
