import styled from "styled-components";
import Select from "react-select";

export const LabInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 400px;
  margin: auto;
  margin-bottom: 6vh;
  color: #666;

  box-sizing: border-box;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2vh;
`;

export const CellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  font-size: 24px;

  & > p:nth-child(2n + 1) {
    font-size: 12px;
  }
`;

export const FullInput = styled.input`
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;

  &:focus {
    background-color: white;
  }
`;
export const Input = styled(FullInput)`
  width: calc(100% - 8px);
  &:nth-child(2n) {
    width: 100%;
  }
`;

export const NewSelect = styled(Select)`
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  background-color: #fff;
  box-sizing: border-box;

  &:focus {
    background-color: white;
  }
`;
