import React from "react";
import AnalytesTable from "./AnalytesTable.js";
import Header from "../../components/header";

function Analyte() {
  return (
    <>
      <Header />
      <AnalytesTable />
    </>
  );
}

export default Analyte;
