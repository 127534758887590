import React, { useState, useEffect } from "react";
import {
  LabInfoWrapper,
  FieldWrapper,
  CellWrapper,
  Input,
  FullInput,
  NewSelect,
} from "./FormStyles";
import Button from "../Button";

import { Countries, States } from "../signup/countries";
import { Loading } from "../Loading";

function LabInfoForm(props) {
  //   let [lab, changeData] = useState({});
  let lab = props.lab;
  const [isUS, setUS] = useState(
    props.lab.country === "United States" ? true : false
  );
  useEffect(() => {
    if (lab.country === "United States") {
      setUS(() => true);
    } else {
      setUS(() => false);
    }
  }, []);

  return (
    <LabInfoWrapper>
      {props.isLoading ? <Loading /> : null}
      <h2>Laboratory #{lab.labID} details</h2>
      <FieldWrapper>
        <CellWrapper>
          <p>Laboratory name</p>
          <FullInput
            name="companyName"
            defaultValue={lab.companyName}
            onChange={props.handleChange}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Address 1</p>
          <FullInput
            name="address1"
            defaultValue={lab.address1}
            onChange={props.handleChange}
          />
        </CellWrapper>
      </FieldWrapper>

      <FieldWrapper>
        <CellWrapper>
          <p>Address 2</p>
          <FullInput
            name="address2"
            defaultValue={lab.address2}
            onChange={props.handleChange}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>City</p>
          <Input
            name="city"
            defaultValue={lab.city}
            onChange={props.handleChange}
          />
        </CellWrapper>
        <CellWrapper>
          <p>
            {isUS || lab.country === "United States" ? "State" : "Province"}
          </p>
          {isUS || lab.country === "United States" ? (
            <NewSelect
              options={States}
              name="state"
              isSearchable={true}
              defaultValue="Alabama"
              value={
                States
                  ? States.find((option) => option.value === lab.state)
                  : ""
              }
              onChange={(selectedOption) => {
                lab.state = selectedOption.value;
              }}
            />
          ) : (
            <Input
              type="text"
              name="state"
              defaultValue={lab.state}
              autoComplete="off"
              onChange={props.handleChange}
              // value={lab.state}
            />
          )}
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Postal code</p>
          <Input
            name="zip"
            defaultValue={lab.zip}
            onChange={props.handleChange}
          />
        </CellWrapper>
        <CellWrapper>
          <p>Country</p>
          <NewSelect
            options={Countries}
            name="country"
            placeholder={lab.country}
            isSearchable={true}
            value={{ value: lab.country, label: lab.country }}
            onChange={(selectedOption) => {
              lab.country = selectedOption.value;
              if (selectedOption.value === "United States") {
                setUS(true);
              } else {
                setUS(false);
              }
            }}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Contact name </p>
          <FullInput
            name="contact1"
            defaultValue={lab.contact1}
            onChange={props.handleChange}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Phone</p>
          <Input
            name="telephone"
            defaultValue={lab.telephone}
            onChange={props.handleChange}
          />
        </CellWrapper>
        <CellWrapper>
          <p>Fax</p>
          <Input
            name="fax"
            defaultValue={lab.fax}
            onChange={props.handleChange}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>email </p>
          <FullInput
            name="email"
            defaultValue={lab.email}
            onChange={props.handleChange}
          />
        </CellWrapper>
      </FieldWrapper>
      {props.message}
      <Button onClick={props.updateLab}>Save changes</Button>
    </LabInfoWrapper>
  );
}

export default LabInfoForm;
