import React from "react";
import { Route, Redirect } from "react-router-dom";
import Login from "../login";
import { history } from "../../index";

export const PublicRoute = () => {
  const userData = JSON.parse(window.localStorage.getItem("loginData"));
  if (userData !== null) {
    if (userData.hasOwnProperty("lab")) {
      return <Redirect to="/samples" history={history} />;
    } else if (userData.user.role === "ADMIN") {
      return <Redirect to="/laboratories" history={history} />;
    }
  } else {
    return <Login />;
  }
};
