import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  labId: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      {
        message: "Please enter valid email",
        excludeEmptyString: true
      }
    )
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password is too short!")
    .max(40, "Password is too long")
    .required("Password is required!")
});

export const signUpValidation = Yup.object().shape({
  labName: Yup.string()
    .min(4, "Lab name is too short")
    .max(50, "Lab name is too long")
    .required("Lab name is required"),
  contactPerson: Yup.string()
    .min(4, "Contact person is too short")
    .max(50, "Contact person is too long")
    .required("Contact person is required"),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      {
        message: "Please enter valid email",
        excludeEmptyString: true
      }
    )
    .required("Email is required"),
  address1: Yup.string().required("Address is required"),
  password: Yup.string()
    .matches(
      /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,})\S$/,
      "Password should contain one uppercase and one lowercase and one number at least"
    )
    .required("Password is required"),
  passwordRepeat: Yup.string().oneOf(
    [Yup.ref("password"), "Passwords are not the same!"],
    "Passwords must match"
  ),
  labType: Yup.array().required("Please choose your lab type"),
  phone: Yup.string().matches(/^[1-9]{1}[0-9]{10}/),
  zip: Yup.string().required("Please enter postal/zip")
});

export const checkoutValidation = Yup.object().shape({
  cardName: Yup.string()
    .min(4)
    .max(100)
    .required(),
  cardNumber: Yup.number()
    .min(16)
    .required()
    .required()
});

export const agreementValidation = Yup.object().shape({
  name: Yup.string().required(),
  jobTitle: Yup.string().required(),
  isAgreedToEthics: Yup.bool().oneOf([true], "Must agree to something")
});

export const labCreationValidation = Yup.object().shape({
  labName: Yup.string()
    .min(4, "Lab name is too short")
    .max(50, "Lab name is too long")
    .required("Lab name is required"),
  contactPerson: Yup.string()
    .min(4, "Contact person is too short")
    .max(50, "Contact person is too long")
    .required("Contact person is required"),
  email: Yup.string()
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      {
        message: "Please enter valid email",
        excludeEmptyString: true
      }
    )
    .required("Email is required"),
  address1: Yup.string().required("Address is required"),
  certificationMethod: Yup.array().required("Please choose your lab type"),
  phone: Yup.string().matches(/^[1-9]{1}[0-9]{9}/),
  zip: Yup.string().required("Please enter postal/zip")
});
