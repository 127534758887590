import React from "react";

import { Content, Ol, H1, H2, Section } from "./styles";

import AgreementForm from "./agreementForm";

const AgreementContent = () => (
  <Content>
    <H1>Statement of Ethics</H1>
    <H2>Agrees that</H2>
    <Section>
      This statement must be signed by a designated representative of the member
      lab to be eligible for NFTA certification.
      <br />
      <br />
      I understand that the value of the NFTA performance certification is based
      upon the independent testing of labs by NFTA, as well as upon the
      consistency of routine analysis by the lab, and the ethical standards set
      by that lab. <br />
      <br />I hereby attest to the following:
    </Section>
    <Ol>
      <li>
        I am analyzing and reporting this sample the same way that I analyze and
        report my production samples in my lab, with the exception of the sample
        processing and reporting requirements of NFTA.
      </li>
      <li>
        I have used methodologies which are the same as practiced by my lab for
        routine samples.
      </li>
      <li>
        I have not consulted any other data or labs with regards to these
        samples, nor have any prior knowledge of the values that I am reporting.
      </li>
      <li>
        I have used the replication protocols specified by NFTA for the running
        of these test samples, including re-packing NIR scans and running
        chemistry analyses in different batches.
      </li>
      <li>
        I will strive to produce the most accurate results possible, and will
        not bias my results to meet market expectations.
      </li>
      <li>
        I understand that violation of these ethical requirements may result in
        loss of my NFTA certification.
      </li>
    </Ol>
    <AgreementForm />
  </Content>
);

export default AgreementContent;
