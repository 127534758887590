import styled from "styled-components";

export const Wrapper = styled.div`
  width: 400px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 0 auto;
`;

export const Input = styled.input`
  &,
  &:active,
  &:focus {
    width: 100%;
    height: 62px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    margin: 0;
  }

  &::placeholder {
    color: #c4c4c4;
  }
`;

export const Button = styled.button`
  margin: 0;
  padding: 0;
  background-color: #383789;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  &:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    color: #383789;
  }
`;
