import styled from "styled-components";

export const Ul = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 19px;
  padding: 0;

  li a,
  li {
    font-family: "ProximaNova", "Roboto", sans-serif;
    font-size: 12px;
    line-height: 15px;
    color: #383789;

    cursor: pointer;

    &:hover,
    &:active,
    &:visited,
    &:link {
      color: #383789;
    }
  }
`;
