import React, { useState } from "react";
import Header from "../../components/header";
import Samples from "./Samples";
import { axiosConfig } from "../../utils/axiosConfig";

import { format } from "date-fns";

import SnackbarMessage from "../../components/Snackbar";

function AdminSamples() {
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState(null);
  const [variant, setVariant] = useState("success");

  function onClose() {
    setOpen(false);
  }

  async function updateSample(sampleID, rest) {
    setLoading(true);
    await axiosConfig
      .post("/sample/" + sampleID, rest)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setOpen(true);

          setVariant("success");
          setMessage("Sample updated successfully");
        } else if (res.status === 500 || res.status === 400) {
          setLoading(false);
          setOpen(true);

          setVariant("error");
          setMessage(res.data.message);
        }
        // getSamples();
      })
      .catch((e) => {
        setLoading(false);
        setOpen(true);

        setVariant("error");
        setMessage(e);
      });
  }

  async function deleteSample(sampleID) {
    setLoading(() => true);
    await axiosConfig.delete("/sample/" + sampleID).then((res) => {
      setLoading(() => false);
      if (res.status === 200) {
        setOpen(true);
        setMessage(() => "Sample deleted successfully");
      } else if (res.status === 500) {
        setVariant("error");
        setMessage(res.data.message);
      } else if (res.status === 404) {
        setVariant("error");
        setMessage("Something went wrong");
      }
      // getSamples();
    });
  }

  async function addSample(sample) {
    // converting strings to correct formats
    sample.createdDate = format(sample.createdDate, "yyyy-MM-dd HH:MM:SS");
    sample.dueDate = format(sample.dueDate, "yyyy-MM-dd HH:MM:SS");
    sample.year = +sample.year;
    setLoading(() => true);
    await axiosConfig.put("/sample", sample).then((res) => {
      setLoading(() => false);
      setOpen(true);
      if (res.status === 200) {
        setMessage("Sample successfully created");
      } else if (res.status === 500) {
        setVariant("error");
        setMessage(res.data.message);
      }
      // getSamples();
    });
  }

  return (
    <>
      <Header />
      <SnackbarMessage
        variant={variant}
        message={message}
        onClose={onClose}
        open={open}
      />
      <Samples
        addRow={addSample}
        updateRow={updateSample}
        deleteRow={deleteSample}
        isLoading={isLoading}
      />
    </>
  );
}

export default AdminSamples;
