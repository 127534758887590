import React from "react";
import Header from "../header";
import styled from "styled-components";

const Details = styled.div`
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  margin: auto;
`;

export const SampleDetails = props => {
  const { companyName } = JSON.parse(
    window.localStorage.getItem("loginData")
  ).lab;
  return (
    <div>
      <Header companyName={companyName} />
      <Details>
        <h1>{props.location.props.name}</h1>
        <p>{props.location.props.description}</p>
      </Details>
    </div>
  );
};
