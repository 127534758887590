import styled from "styled-components";
import { Button } from "@material-ui/core";

export const TableWrapper = styled.div`
  width: 100%;
  margin: auto;
  & table {
    color: #666;
    width: 100%;
    margin: auto;
  }
  & tr {
    background-color: #f6f6f6;
  }
  & tr:nth-child(even) {
    background-color: white;
  }
  & input[type="checkbox"]:disabled {
    color: #90caf9;
  }
  & input[type="checkbox"]:checked {
    color: #90caf9;
  }
`;

export const StyledButton = styled(Button)`
  background-color: #383789 !important;
  color: white !important;
`;

export const ModalBody = styled.div`
  position: relative;
  left: -50%;
  top: 200px;
  background: white;
  width: 500px;
  height: 135px;
  margin: auto;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  padding: 20px;
  p {
    margin: auto;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
