import React, { Fragment } from "react";
import Header from "../header";
import { LabInfoForm } from "./form";

function LabInfo() {
  return (
    <Fragment>
      <Header />
      <LabInfoForm />
    </Fragment>
  );
}

export default LabInfo;
