import React, { useState, useEffect } from "react";
import Header from "../header";
import { axiosConfig } from "../../utils/axiosConfig.js";
import { ReportSelectors, ReportPageWrapper } from "./styles";
import { Loading } from "../Loading";
import Select from "react-select";
import SnackbarMessage from "../Snackbar";

const certificationMethods = [
  { value: "NIRS", label: "NIRS" },
  { value: "WET", label: "WET" },
];

export function Reports(props) {
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "35%",
      marginBottom: "50px",
    }),
  };
  const [reportTypes, setReportTypes] = useState([]);
  const [reportType, setReportType] = useState("");
  const [years, setYears] = useState([]);
  const [fileName, setFileName] = useState("");
  const [reportYear, setReportYear] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [labs, setLabs] = useState([]);
  const [lab, setLab] = useState(0);
  const [labName, setLabName] = useState("");
  const [labRequired, setLabRequired] = useState(false);
  const [message, setMessage] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [certMethod, setCertMethod] = useState("");
  const [selectedLab, setSelectedLab] = useState({});
  const localData = JSON.parse(localStorage.getItem("loginData"));

  async function getLabs() {
    if (localData.user.role === "ADMIN") {
      setAdmin(true);
      setLoading(true);
      axiosConfig
        .get("/lab/all")
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            setLabs(res.data);
          }
        })
        .catch((e) => {
          setLoading(false);
          setMessage(e.data.message);
          setSnackOpen(true);
        });
    }
  }

  async function getReportTypes() {
    setLoading(true);
    axiosConfig
      .get("/report/types")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setReportTypes(res.data);
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.data.message);
        setSnackOpen(true);
      });
    await getLabs();
  }

  async function optionChange(e) {
    setReportType(e.value);
    setLabRequired(e.value.requireLab);
    setYears([]);
    setReportYear("");
    setLoading(true);
    if (!isAdmin && localData.lab.certificationMethod !== "BOTH") {
      setCertMethod({
        value: localData.lab.certificationMethod,
        label: localData.lab.certificationMethod,
      });
    }
    axiosConfig
      .get("/report/years", { params: { type: e.value.id } })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setYears(res.data.years);
          setFileName(res.data["future-filename"]);
        }
      })
      .catch((e) => {
        setLoading(false);
        setMessage(e.data.message);
        setSnackOpen(true);
      });
  }

  function handleYearChange(e) {
    setReportYear(e.value);
  }

  async function downloadReport() {
    setLoading(true);
    const labID = isAdmin
      ? lab
      : JSON.parse(window.localStorage.getItem("loginData")).lab.labID;
    axiosConfig
      .get("/report", {
        responseType: "arraybuffer",
        params: {
          type: reportType.id,
          year: reportYear,
          certificationMethod: certMethod.value,
          ...(labRequired ? { labID: labID } : {}),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          const link = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([res.data]));
          link.href = url;
          link.download = fileName;
          link.click();
        }
      })
      .catch((e) => {
        setLoading(false);
        const res = JSON.parse(Buffer.from(e.data).toString("utf8"));
        setMessage(res.message);
        setSnackOpen(true);
        // props.history.push("/");
      });
  }

  function labChange(e) {
    setLab(e.value.labID);
    setLabName(e.value.companyName);
    setSelectedLab(e.value);
    if (e.value.certificationMethod !== "BOTH") {
      setCertMethod({
        value: e.value.certificationMethod,
        label: e.value.certificationMethod,
      });
    }
    // if (e.value.certificationMethod)
  }

  function certificationMethodChange(selectedOption) {
    setCertMethod(selectedOption);
  }

  useEffect(() => {
    getReportTypes();
    if (isAdmin && localData.lab.certificationMethod !== "BOTH") {
      setCertMethod({
        value: localData.lab.certificationMethod,
        label: localData.lab.certificationMethod,
      });
    }
  }, []);
  return (
    <>
      {loading ? <Loading /> : null}
      <>
        <Header />
        <ReportPageWrapper>
          <h1>Reports</h1>
          <ReportSelectors>
            <Select
              styles={customStyles}
              value={{ label: reportType.description || "Select Report Type" }}
              onChange={optionChange}
              options={reportTypes.map((x, idx) => {
                return {
                  value: x,
                  label: x.description,
                  key: idx,
                };
              })}
            />
            {isAdmin ? (
              <>
                <Select
                  isSearchable={true}
                  styles={customStyles}
                  isDisabled={!labRequired}
                  onChange={labChange}
                  value={{ label: labName || "Select Lab" }}
                  options={labs.map((x) => {
                    return { value: x, label: x.companyName };
                  })}
                />
              </>
            ) : null}
            {isAdmin && labRequired && (
              <Select
                styles={customStyles}
                onChange={certificationMethodChange}
                options={certificationMethods}
                value={
                  selectedLab.certificationMethod === "BOTH"
                    ? certMethod
                    : {
                        value: selectedLab.certificationMethod,
                        label: selectedLab.certificationMethod,
                      }
                }
                placeholder="Select Certification Method"
                isDisabled={selectedLab.certificationMethod !== "BOTH"}
              />
            )}
            {isAdmin ? null : (
              <Select
                styles={customStyles}
                onChange={certificationMethodChange}
                options={certificationMethods}
                value={certMethod}
                defaultValue={certMethod}
                placeholder="Select Certification Method"
                isDisabled={
                  isAdmin
                    ? false
                    : localData?.lab?.certificationMethod !== "BOTH"
                }
              />
            )}
            <Select
              styles={customStyles}
              isDisabled={years.length === 0}
              onChange={handleYearChange}
              value={{ label: reportYear || "Select Report Year" }}
              options={years.map((x) => {
                return { value: x, label: x };
              })}
            />{" "}
            <button disabled={!reportYear} onClick={downloadReport}>
              Download report
            </button>
          </ReportSelectors>
          <SnackbarMessage
            variant={"error"}
            message={message}
            onClose={() => setSnackOpen(false)}
            open={snackOpen}
          />
        </ReportPageWrapper>
      </>
    </>
  );
}
