import React from "react";
import LabHeader from "./labHeader";
import AdminHeader from "./adminheader";
import Login from "../login";

function Header(props) {
  const userData = JSON.parse(window.localStorage.getItem("loginData"));
  if (userData !== null) {
    if (userData.user.role === "ADMIN") {
      return <AdminHeader history={props.history} companyName={"NFTA"} />;
    } else if (userData.user.role !== "ADMIN") {
      return <LabHeader history={props.history} />;
    }
  } else {
    return <Login />;
  }
}

export default Header;
