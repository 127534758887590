import React, { useEffect, useState } from "react";
import { axiosConfig } from "../../utils/axiosConfig.js";
import axios from "axios";
import Header from "../../components/header";
import { Loading } from "../../components/Loading";
import TextField from "@material-ui/core/TextField";
import SnackbarMessage from "../../components/Snackbar";
import { H1, Submittions, Analyte } from "./styles.js";
import {
  ContentWrapper,
  QuestionnaireWrapper,
  Container,
} from "../../components/questionnaire/styles.js";
import Button from "../../components/Button";
import QuestionnaireInfo from "../../components/questionnaire/questionnaireInfo.js";

function Rma(props) {
  const [isLoading, setLoading] = useState(false);
  const [rma, setRma] = useState([]);
  const [nonCertAnalyte, setNonCertAnalyte] = useState([]);
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(true);
  const [alreadyCompleted, setAlreadyCompleted] = useState(false);
  const { sampleID, certMethod, description, createdDate, dueDate } =
    props.location;

  function onClose() {
    setOpen(false);
  }

  const styles = {
    helper: {
      color: "#F9A825",
    },
  };
  useEffect(() => {
    async function getRma() {
      let array1 = [];
      let array2 = [];
      let array3 = [];
      setLoading(true);
      const mergeById = (a1, a2) => {
        let merged = [];

        for (let i = 0; i < a1.length; i++) {
          merged.push({
            ...a1[i],
            ...a2.find((itmInner) => itmInner.analyteID === a1[i].analyteID),
          });
        }
        return merged;
      };

      try {
        await axios
          .all([
            await axiosConfig.get("/analyte/all"),
            await axiosConfig.get(
              "/rma/results?sampleID=" + sampleID + "&method=" + certMethod
            ),
          ])
          .then(
            axios.spread((analytes, results) => {
              if (analytes.status === 200 && results.status === 200) {
                if (results.data.length > 0) {
                  if (
                    results.data.filter(
                      (a) => a.certificationMethod === certMethod
                    ).length > 0
                  ) {
                    setAlreadyCompleted(true);
                    setResults(
                      results.data.filter(
                        (a) => a.certificationMethod === certMethod
                      )
                    );
                  }
                }

                array3 = mergeById(analytes.data, results.data);
                array3.map((a) => {
                  if (a.certificationAnalyte) {
                    array1.push(a);
                  } else {
                    array2.push(a);
                  }
                });
                setRma(array1);
                setNonCertAnalyte(array2);
                setLoading(false);
              }
            })
          );
      } catch (e) {
        setLoading(false);
        props.history.push("/");
      }
    }
    getRma();
  }, []);

  function handleChange(e, analyteID) {
    const { name, value } = e.target;
    let newRes = {
      analyteID,
      [name]: +value,
    };
    for (let result of results) {
      if (result.analyteID === newRes.analyteID) {
        setResults(...results, { [name]: value });
      }
    }
    setResults(() => [...results, { ...newRes }]);
    // results.push(newRes);
    // setResults(() => results);
  }

  function getValueForAnalyteId(analyteID, repIndex) {
    let value;
    for (let result of results) {
      if (result.analyteID === analyteID && result[repIndex]) {
        value = result[repIndex];
      }
    }
    return value;
  }

  function getErrorText(analyteObject, repInex) {
    if (validateInput(analyteObject, repInex)) {
      return "";
    }
    if (analyteObject.analyte === "Dry Matter") {
      return "Expected >80%";
    } else if (analyteObject.analyte === "Crude Protein") {
      return "Expected 5% - 25%";
    } else if (analyteObject.analyte === "ADF") {
      return "Expected 16% - 40%";
    } else if (analyteObject.analyte === "NDF") {
      return "Expected >30%";
    } else {
      return "";
    }
  }
  function validateInput(analyteObject, repIndex) {
    const input = parseFloat(
      getValueForAnalyteId(analyteObject.analyteID, repIndex)
    );
    if (input > 100) {
      return false;
    }
    if (analyteObject.analyte === "Dry Matter") {
      return isNaN(input) || input > 80;
    } else if (analyteObject.analyte === "Crude Protein") {
      return isNaN(input) || (input >= 5 && input <= 25);
    } else if (analyteObject.analyte === "ADF") {
      return isNaN(input) || (input >= 16 && input <= 40);
    } else if (analyteObject.analyte === "NDF") {
      return isNaN(input) || input > 30;
    } else {
      return true;
    }
  }

  async function handleClick() {
    async function postResults() {
      setLoading(true);
      try {
        await axiosConfig
          .post("/rma/results/" + sampleID, formattedData, {
            params: { method: certMethod },
          })
          .then((res) => {
            setLoading(false);
            if (res.status === 200) {
              setSuccess(true);
              setMessage(res.data.message);
              setOpen(true);
            } else if (res.status === 400 || res.status === 500) {
              setSuccess(false);
              setMessage(res.data.message);
              setLoading(false);
            }
          });
      } catch (e) {
        setSuccess(false);
        setMessage(e.data.message);
        setLoading(false);
        setOpen(true);
      }
    }

    const formattedData = Object.values(
      results.reduce((acc, item) => {
        acc[item.analyteID] = Object.assign(acc[item.analyteID] || {}, item);
        return acc;
      }, {})
    );
    postResults();
  }

  return (
    <QuestionnaireWrapper>
      <Header />
      <ContentWrapper>
        {isLoading ? <Loading /> : null}
        <H1>
          NFTA Testing - #{sampleID} ({certMethod})
        </H1>
        <Container>
          <QuestionnaireInfo
            description={description}
            createdDate={createdDate}
            dueDate={dueDate}
            certMethod={certMethod}
          />
          <Submittions style={{ width: "150%" }}>
            <h3>Certification Elements</h3>
            <p>
              No Response is considered a failure. Results must be entered on
              as-received basis.
            </p>

            {rma.map((a) => (
              <Analyte key={a.analyte}>
                <h6>{a.analyte}</h6>
                <TextField
                  name="rep1"
                  onChange={(e) => handleChange(e, a.analyteID)}
                  label="Replicate 1"
                  helperText={getErrorText(a, "rep1")}
                  defaultValue={alreadyCompleted ? a.rep1 : undefined}
                  FormHelperTextProps={{ style: styles.helper }}
                />

                <TextField
                  name="rep2"
                  onChange={(e) => handleChange(e, a.analyteID)}
                  label="Replicate 2"
                  helperText={getErrorText(a, "rep2")}
                  defaultValue={alreadyCompleted ? a.rep2 : undefined}
                  FormHelperTextProps={{ style: styles.helper }}
                />
                <TextField
                  name="rep3"
                  onChange={(e) => handleChange(e, a.analyteID)}
                  label="Replicate 3"
                  helperText={getErrorText(a, "rep3")}
                  defaultValue={alreadyCompleted ? a.rep3 : undefined}
                  FormHelperTextProps={{ style: styles.helper }}
                />
              </Analyte>
            ))}
            <h3>Optional Testing Elements</h3>
            <p>
              Not required for certification.You must supply all three
              replicates for complete statistical analysis.
            </p>
            {nonCertAnalyte.map((a) => (
              <Analyte key={a.analyte}>
                <h6>{a.analyte}</h6>
                <TextField
                  name="rep1"
                  onChange={(e) => handleChange(e, a.analyteID)}
                  label="Replicate 1"
                  defaultValue={alreadyCompleted ? a.rep1 || " " : undefined}
                />
                <TextField
                  name="rep2"
                  onChange={(e) => handleChange(e, a.analyteID)}
                  label="Replicate 2"
                  defaultValue={alreadyCompleted ? a.rep2 : undefined}
                />
                <TextField
                  name="rep3"
                  onChange={(e) => handleChange(e, a.analyteID)}
                  label="Replicate 3"
                  defaultValue={alreadyCompleted ? a.rep3 : undefined}
                />
              </Analyte>
            ))}
            <Button type="button" onClick={handleClick}>
              Submit results
            </Button>
          </Submittions>
          <SnackbarMessage
            variant={success ? "success" : "error"}
            message={message}
            onClose={onClose}
            open={open}
          />
        </Container>
      </ContentWrapper>
    </QuestionnaireWrapper>
  );
}

export default Rma;
