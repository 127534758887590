import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import Checkbox from "@material-ui/core/Checkbox";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { axiosConfig } from "../../utils/axiosConfig.js";
import { TableWrapper } from "./styles";
import SnackbarMessage from "../../components/Snackbar";
import {
  StyledButton,
  ModalBody,
  ButtonsWrapper,
} from "./styles";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#383789",
    },
  },
});

function Samples({ updateRow, deleteRow, addRow, isLoading }) {
  const columns = [
    {
      title: "Sample ID",
      readonly: true,
      editable: "never",
      field: "sampleID",
    },
    {
      title: "Sample Type",
      field: "sampleType",
    },
    {
      title: "Year",
      type: "numeric",
      field: "year",
    },
    {
      title: "Description",
      field: "description",
    },
    {
      title: "Created at",
      field: "createdDate",
      type: "date",
      render: (rowData) => moment(rowData.createdDate).format("YYYY-MM-DD"),
    },
    {
      title: "Due Date",
      field: "dueDate",
      type: "date",
      render: (rowData) => moment(rowData.dueDate).format("YYYY-MM-DD"),
    },  
    {
      title: "Corn Silage",
      field: "csSample",
      type: "boolean",
      render: (rowData) => (
        <Checkbox
          type="checkbox"
          value="csSample"
          name="csSample"
          defaultChecked={rowData.csSample}
          style={{
            color: "#383789",
          }}
          disabled
          readOnly
          onChange={(e) => {
            const { name, checked } = e.target;
            rowData.tableData[name] = !!checked;
          }}
        />
      ),
    },
    {
      title: "Status",
      field: "status",
      initialEditValue: "Open",
      lookup: {
        Open: "Open",
        Closed: "Closed",
      },
    },
    {
      title: "Sample calc.",
      field: "calculation",
      type: "action",
      render: (rowData) => 
      <Tooltip title="Run sample calculation">
      <IconButton aria-label="delete">
        <AccessTimeIcon className="time-icon" onClick={() => 
        {
          setSelectedRow(rowData.sampleID);
          setOpen(true);
          setStatus(rowData.status)
        }}>
        </AccessTimeIcon>
      </IconButton>
    </Tooltip>
    }
  ];

  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [variant, setVariant] = React.useState('success');
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
                 
  useEffect(() => {
    async function getSamples() {
      axiosConfig.get("/sample/all").then((res) => {
        setTableData(res.data);
      });
    }
    getSamples();
  }, []);

  async function runSampleCalc() {
    setOpen(false);
    axiosConfig.post(`/sample/${selectedRow}/calculate`)
    .then((res) => {
      setVariant("success");
      setMessage(res.data.message);
      setSnackOpen(true);
    })
    .catch(err => {
      setMessage(err.data.message);
      setVariant("error");
      setSnackOpen(true);
    });
  }

  return (
    <TableWrapper>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title="Samples"
          columns={columns}
          isLoading={isLoading}
          localization={{
            body: {
              editRow: {
                deleteText: "Are you sure you want to delete this sample?",
              },
            },
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30],
            actionsColumnIndex: -1,
          }}
          style={{
            maxWidth: "80%",
            margin: "auto",
          }}
          data={Array.from(tableData)}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  const { sampleID, ...rest } = newData;
                  const data = Array.from(tableData);
                  data.push(newData);
                  setTableData(data);
                  addRow(rest);
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  const { sampleID, ...rest } = newData;
                  const data = Array.from(tableData);
                  rest.dueDate = moment(newData.dueDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  rest.createdDate = moment(newData.createdDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  );
                  data[data.indexOf(oldData)] = newData;

                  setTableData(data);
                  updateRow(sampleID, rest);
                  resolve();
                }, 700);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  const data = Array.from(tableData);
                  data.splice(oldData.tableData.id, 1);
                  setTableData(data);
                  deleteRow(oldData.sampleID);
                  resolve();
                }, 600);
              }),
          }}
        />
      </MuiThemeProvider>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div style={{ position: "absolute", left: "50%", outline: "none" }}>
          <ModalBody>
            {status === 'Closed' && <p>Do you want to proceed with running sample calculations?</p>}
            {status === 'Open' && <p>Cannot run calculation for open samples</p>}
            <ButtonsWrapper>
              {status === 'Closed' && <StyledButton onClick={runSampleCalc}>
                Confirm
              </StyledButton>}
              <StyledButton onClick={() => setOpen(false)}>{status === 'Closed' ? 'Cancel' : 'Close'}</StyledButton>
            </ButtonsWrapper>
          </ModalBody>
        </div>
      </Modal>
      <SnackbarMessage
        variant={variant}
        message={message}
        onClose={() => {
          setSnackOpen(false);
        }}
        open={snackOpen}
      />
    </TableWrapper>
  );
}
export default Samples;
