import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function EmailDialog(props) {
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
        width="450px"
      >
        <DialogTitle id="form-dialog-title">Change email address</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.showCode
              ? "Enter verification code"
              : "Enter your new email address"}
            .
          </DialogContentText>
          {props.showCode ? (
            <TextField
              autoFocus
              margin="dense"
              id="code"
              label="Verification code"
              type="text"
              value={props.code}
              onChange={props.handleCodeChange}
              fullWidth
            />
          ) : (
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="new email"
              type="email"
              value={props.newEmail}
              onChange={props.handleEmailChange}
              fullWidth
            />
          )}
          <p>{props.emailMessage.length !== 0 ? props.emailMessage : null}</p>
        </DialogContent>
        <DialogActions>
          {props.showCode ? (
            <Button onClick={props.sendCode} color="primary">
              Change email
            </Button>
          ) : (
            <Button onClick={props.getCode} color="primary">
              Get verification code
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={props.snackbarOpen}
        autoHideDuration={6000}
        onClose={props.handleSnackbarclose}
      >
        <Alert color={props.color}>{props.snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
}

export default EmailDialog;
