import React, { useState, useEffect } from "react";
import {
  LabInfoWrapper,
  FieldWrapper,
  CellWrapper,
  Input,
  FullInput,
  NewSelect
} from "./styles";
import Button from "../Button";
import { axiosConfig } from "../../utils/axiosConfig.js";
import EmailDialog from "./Dialog.js";
import { Countries, States } from "../signup/countries";
import { Loading } from "../Loading";

export function LabInfoForm(props) {
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [code, setCode] = useState("");
  const [showCode, setShowcode] = useState(false);
  const [message, setMessage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [emailMessage, setEmailmessage] = useState("");
  const [color, setColor] = useState("");
  let [isUS, setUS] = useState(false);
  let [labData, changeData] = useState({});
  let isAdmin = false;

  if (
    JSON.parse(window.localStorage.getItem("loginData")).user.role === "ADMIN"
  ) {
    isAdmin = true;
  }

  function handleClose() {
    setOpen(false);
    setNewEmail("");
    setCode("");
    setShowcode(false);
  }

  function handleEmailChange(e) {
    setNewEmail(e.target.value);
  }

  function handleCodeChange(e) {
    setCode(e.target.value);
  }

  function handleSnackbarclose() {
    setSnackbarOpen(false);
  }

  async function getCode() {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!newEmail.match(emailRegex)) {
      setEmailmessage("Please enter valid email.");
    } else if (newEmail === labData.email) {
      setEmailmessage("You cannot change to your current email.");
    } else {
      setEmailmessage("");
      setLoading(true);
      await axiosConfig
        .post("/user/updateEmailRequest", null, { params: { newEmail } })
        .then(res => {
          setLoading(false);
          if (res.status === 200) {
            setShowcode(true);
          } else {
            setColor("error");
            setSnackbarMessage(res.data.message);
            setSnackbarOpen(true);
          }
        });
    }
  }

  async function sendCode() {
    setLoading(true);
    await axiosConfig
      .post("/user/updateEmail", null, { params: { confirmationCode: code } })
      .then(res => {
        setLoading(false);

        if (res.status === 200) {
          setColor("success");
          setOpen(false);
          setSnackbarMessage(res.data.message);
          setSnackbarOpen(true);
          getLab();
          handleClose();
        } else {
          setColor("error");
          setSnackbarMessage(res.data.message);
          setSnackbarOpen(true);
        }
      });
  }

  async function getLab() {
    setLoading(() => true);
    if (isAdmin) {
      await axiosConfig
        .get(`/lab/${props.labID}`)
        .then(res => {
          setLoading(() => false);
          if (res.status) {
            changeData(res.data);
          }
        })
        .catch(e => {
          setLoading(() => false);
          console.error(e);
        });
    } else {
      await axiosConfig
        .get("/lab")
        .then(res => {
          setLoading(() => false);
          if (res.status === 200) {
            changeData(res.data.lab);
            if (res.data.country === "United States") {
              setUS(() => true);
            } else {
              setUS(() => false);
            }
          } else if (res.status === 400) {
            setMessage(res.data.message);
          }
        })
        .catch(e => console.error(e));
    }
  }

  useEffect(() => {
    getLab();
  }, []);

  function handleChange(evt) {
    const {
      target: { name, value }
    } = evt;
    labData[name] = value;
    changeData(() => labData);
  }

  async function updateLab() {
    setLoading(() => true);
    await axiosConfig
      .post("/lab", labData)
      .then(res => {
        setLoading(() => false);
        if (res.status === 200) {
          setMessage("Lab info updated successfully");
        } else setMessage(res.data.message);
      })
      .catch(e => {
        setLoading(() => false);
        setMessage(e.response);
      });
  }

  return (
    <LabInfoWrapper>
      {isLoading ? <Loading /> : null}
      <h2>Laboratory details</h2>
      <FieldWrapper>
        <CellWrapper>
          <p>Email</p>
          <FullInput name="companyName" disabled value={labData.email} />
          <p
            onClick={() => {
              setOpen(true);
            }}
            style={{ cursor: "pointer", color: "#383789" }}
          >
            Change email
          </p>
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Laboratory name</p>
          <FullInput
            name="companyName"
            defaultValue={labData.companyName}
            onChange={handleChange}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Address 1</p>
          <FullInput
            name="address1"
            defaultValue={labData.address1}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Address 2</p>
          <FullInput
            name="address2"
            defaultValue={labData.address2}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>City</p>
          <Input
            name="city"
            defaultValue={labData.city}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
        <CellWrapper>
          <p>
            {isUS || labData.country === "United States" ? "State" : "Province"}
          </p>
          {isUS || labData.country === "United States" ? (
            <NewSelect
              options={States}
              name="state"
              isSearchable={true}
              defaultValue={
                States
                  ? States.find(option => option.value === labData.state)
                  : ""
              }
              onChange={selectedOption => {
                labData.state = selectedOption.value;
              }}
            />
          ) : (
            <Input
              type="text"
              name="state"
              defaultValue={labData.state}
              autoComplete="off"
              onChange={e => handleChange(e)}
            />
          )}
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Postal code</p>
          <Input
            name="zip"
            defaultValue={labData.zip}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
        <CellWrapper>
          <p>Country</p>
          <NewSelect
            options={Countries}
            name="country"
            isSearchable={true}
            value={{ value: labData.country, label: labData.country }}
            onChange={selectedOption => {
              changeData(data => ({
                ...data,
                country: selectedOption.value
              }));
              if (selectedOption.value === "United States") {
                setUS(() => true);
                setUS(true);
              } else {
                setUS(() => false);
              }
            }}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Contact name </p>
          <FullInput
            name="contact1"
            defaultValue={labData.contact1}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
      </FieldWrapper>
      <FieldWrapper>
        <CellWrapper>
          <p>Phone</p>
          <Input
            name="telephone"
            defaultValue={labData.telephone}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
        <CellWrapper>
          <p>Fax</p>
          <Input
            name="fax"
            defaultValue={labData.fax !== null ? labData.fax : null}
            onChange={e => handleChange(e)}
          />
        </CellWrapper>
      </FieldWrapper>
      {message}
      <Button onClick={updateLab}>Save changes</Button>
      <EmailDialog
        newEmail={newEmail}
        code={code}
        open={open}
        getCode={getCode}
        sendCode={sendCode}
        handleEmailChange={handleEmailChange}
        handleCodeChange={handleCodeChange}
        handleClose={handleClose}
        showCode={showCode}
        snackbarOpen={snackbarOpen}
        emailMessage={emailMessage}
        snackbarMessage={snackbarMessage}
        handleSnackbarclose={handleSnackbarclose}
        color={color}
      />
    </LabInfoWrapper>
  );
}
