import styled from "styled-components";
import { Link } from "react-router-dom";

export const StyledLink = styled(Link)`
  color: #383789;
  font-size: 16px;
  & :hover,
  & :focus,
  & :active,
  :visited {
    color: #383789;
  }
`;
