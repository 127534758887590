import styled from "styled-components";

export const LabsWrapper = styled.div`
  width: 80%;
  height: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & > input {
    width: 362px;
    margin: 0;
  }
  & > h1:nth-child(1) {
    margin-top: 30px;
  }

  & > input,
  & > input:active,
  & > input:focus {
    width: 362px;
    outline: none;
    height: 62px;
    margin: 0;
  }
`;

export const LabWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  flex: 1 0 21%;
  flex-wrap: wrap;
`;

export const P = styled.p`
  font-size: 16px;
`;

export const Lab = styled.div`
  flex: 1 0 21%;
  height: 180px;
  display: flex;
  margin: calc(19px / 2);
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  background-color: #f6f6f6;

  margin-top: 0;
  &:nth-child(4n + 0) {
    margin-right: 0;
  }
  &:nth-child(4n + 1) {
    margin-left: 0;
  }
`;

export const H1 = styled.h1`
  font-size: 20px;
  margin-left: 15px;
`;

export const HeaderTitle = styled.h1`
  font-size: 24px;
  color: #666666;
  margin: 0;
  margin-bottom: 11px;
  ~ input {
    margin-bottom: 19px;
  }
`;

export const LabId = styled(H1)`
  color: #383789;
  font-size: 16px;
`;

export const Name = styled.div`
  font-size: 16px;
  margin: 0;
  margin-left: 15px;
  color: #666666;
  & > p {
    margin: 0;
  }
  margin-top: 30px;
`;

export const Logo = styled.img`
  width: 30px;
  height: 30px;
`;

export const Logos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
  margin-left: 11px;
  margin-bottom: 9px;

  & > :nth-child(2) {
    width: 25px;
    height: 25px;
    vertical-align: middle;
  }
`;

export const Ul = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  list-style: none;
  font-size: 16px;
  height: 120px;
`;

export const AddLabText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: bold;
  align-self: flex-end;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 32px;
  color: #383789;
  width: 200px;
  text-align: right;
  & > * {
    color: #383789;
  }
  button {
    padding: 0 !important;
  }
`;

export const InputLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  & > input,
  & > input:focus,
  & > input: active {
    width: 40%;
  }
`;
