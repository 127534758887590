import React from "react";
import { Switch, Route } from "react-router-dom";

import { ProtectedRoute } from "./ProtectedRoute";
import { PublicRoute } from "./PublicRoute";
import Login from "../login";
import SignUp from "../signup";
import Agreement from "../agreement";
import { Samples } from "../samples";
import { SampleDetails } from "../sampledetails";
import Payment from "../payment";
import LabInfo from "../LabInfo";
import Laboratories from "../Laboratories";
import { ForgotPassword } from "../ForgotPassword";
import Questionnaire from "../questionnaire";
import Laboratory from "../Lab";
import EmailVerification from "../signup/EmailVerification";
import ResetPassword from "../ResetPassword";
import AddLab from "../AddLab";
import AdminSamples from "../../containers/AdminSamples";
import Rma from "../../containers/rma";
import Analytes from "../../containers/Analytes";
import { Reports } from "../Reports";
import AdminSettings from "../../containers/adminSettings";

export const Routes = () => (
  <Switch>
    <PublicRoute exact path="/" component={Login} />
    <Route path="/forgotpassword" component={ForgotPassword} />
    <Route path="/signup" component={SignUp} />
    <Route path="/resetPassword" component={ResetPassword} />
    <Route path="/agreement" component={Agreement} />
    <Route path="/verify" component={EmailVerification} />
    <ProtectedRoute path="/samples" component={Samples} />
    <ProtectedRoute path="/adminsamples" component={AdminSamples} />
    <ProtectedRoute path="/samples/:sampleId" component={SampleDetails} />
    <ProtectedRoute path="/payment" component={Payment} />
    <ProtectedRoute path="/labinfo" component={LabInfo} />
    <ProtectedRoute exact path="/laboratories" component={Laboratories} />
    <ProtectedRoute exact path="/laboratories/:labID" component={Laboratory} />
    <ProtectedRoute path="/questionnaire" component={Questionnaire} />
    <ProtectedRoute path="/lab" component={Laboratory} />
    <ProtectedRoute path="/reports" component={Reports} />
    <ProtectedRoute path="/addlab" component={AddLab} />
    <ProtectedRoute path="/rma" component={Rma} />
    <ProtectedRoute path="/analytes" component={Analytes} />
    <ProtectedRoute path="/settings" component={AdminSettings} />
  </Switch>
);
