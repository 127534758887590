import React, { useState } from "react";
import arrow from "../../arrow.svg";
import dollar from "../../dollar.svg";
import dollarblue from "../../dollarblue.svg";
import { Logo } from "../Laboratories/styles";
import moment from "moment";
import { Info, H1, Arrow, Span, Accordion, P, LabName } from "./styles";

export const LabInfoHeader = props => {
  const { lab } = props;
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);

  function setPaymentMethod(e) {
    const payment = e.target.getAttribute("name").toUpperCase();
    lab.paymentMethod = payment;
    lab.paymentDate = moment().format("MM-DD-YYYY HH:mm:ss");
    setActive(() => !active);
    setShow(() => !show);
  }

  const ActiveAccordion = () => (
    <Accordion show={show}>
      <P name="NONE" onClick={setPaymentMethod}>
        Inactive
      </P>
    </Accordion>
  );

  const InactiveAccordion = () => (
    <Accordion show={show}>
      <P name="CARD" onClick={setPaymentMethod}>
        Card
      </P>
      <P name="ACH" onClick={setPaymentMethod}>
        ACH
      </P>
      <P name="WIRE" onClick={setPaymentMethod}>
        Wire transfer
      </P>
      <P name="CHECK" onClick={setPaymentMethod}>
        Check
      </P>
    </Accordion>
  );

  const DisableAccordion = () =>
    lab.paymentMethod === "NONE" ? <InactiveAccordion /> : <ActiveAccordion />;

  function toggleShow() {
    setShow(() => !show);
  }

  const paymentDate = () =>
    "Payment verified by " +
    lab.paymentMethod +
    " on " +
    moment(lab.paymentDate + "").format("MM/DD/YYYY");

  return (
    <Info>
      <span>
        <LabName>{lab.companyName}</LabName>
      </span>
      <Span>
        <p>{lab.paymentMethod !== "NONE" ? paymentDate() : null}</p>
        <div>
          <H1>
            <Logo
              src={lab.paymentMethod === "NONE" ? dollar : dollarblue}
              style={{
                width: "22px",
                height: "25px",
                marginRight: "4px",
                verticalAlign: "middle"
              }}
              alt="dollar"
            />
            <P
              style={{ verticalAlign: "bottom" }}
              name="paymentMethod"
              onClick={toggleShow}
            >
              {lab.paymentMethod === "NONE" ? "Inactive" : "Active"}
            </P>
            <Arrow src={arrow} alt="arrow" />
          </H1>
          <DisableAccordion />
        </div>
      </Span>
    </Info>
  );
};
