import styled from "styled-components";

export const ReportSelectors = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  select,
  button {
    height: 50px;
    width: 35%;
    &:placeholder {
      color: #666;
    }
    margin-bottom: 50px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    background-color: #fff;
  }

  button {
    margin: 0;
    padding: 0;
    background-color: #383789;
    width: 35%;
    border-radius: 8px;
    height: 50px;
    color: white;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
      background-color: #ccc;
      color: #383789;
    }
  }

  @media screen and (max-width: 768px) {
    select,
    button {
      width: 100%;
    }
  }
`;

export const ReportPageWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: auto;
  h1 {
    width: 100%;
    border-bottom: solid 1px #dedede;
  }
`;
