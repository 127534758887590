import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./styles/global-styles";
import theme from "./styles/theme";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "../node_modules/normalize.css/normalize.css";

export const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <ThemeProvider theme={theme}>
      {process.env.REACT_APP_NODE_ENV !== "production" && <h2 style={{textAlign: 'center'}}>DEVELOPMENT ENVIRONMENT</h2>}
      <App />
    </ThemeProvider>
    <GlobalStyle />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
