// TODO: Add theme options here like colors to reference. These are examples from another project
export default {
  skyBlue: '#38abd8',
  lightGray: '#f1f1f1',
  lightTextColor: '#9b9b9b',
  darkBlueBackground: '#0f739a',
  inputBorderColor: '#979797',

  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  }
};
