import React, { useState } from "react";
import {
  VerificationWrapper,
  VerifyButton,
  VericiationNumberInput,
} from "./styles";
import H1 from "../h1";
import { Loading } from "../Loading";
import { axiosConfig } from "../../utils/axiosConfig";

function EmailVerification(props) {
  let [isLoading, setLoading] = useState(false);
  let [error, setError] = useState(null);

  const [verificationNumber, setVerificationNumber] = useState("");

  function handleChange(e) {
    const { value } = e.target;
    setVerificationNumber(value);
  }

  function handleSubmit() {
    setLoading(() => true);
    axiosConfig
      .post("/user/activate", null, {
        params: {
          email: props.location.state.email,
          token: verificationNumber,
        },
      })
      .then((res) => {
        setLoading(() => false);
        if (res.status === 200) {
          props.history.push("/agreement");
        } else if (res.status === 400) {
          setError(res.data.message);
        }
      })
      .catch(() => {
        setError("The code you entered is not correct");
        setLoading(() => false);
      });
  }

  return (
    <VerificationWrapper>
      {isLoading ? <Loading /> : null}
      <H1>Verification code was sent to your email. Enter it below.</H1>
      <VericiationNumberInput
        type="text"
        name="verification"
        placeholder="Email verification number"
        onChange={handleChange}
      />
      {error}
      <VerifyButton type="button" onClick={handleSubmit}>
        Verify email
      </VerifyButton>
    </VerificationWrapper>
  );
}

export default EmailVerification;
