import React, { useState } from "react";
import { axiosConfig } from "../../utils/axiosConfig";

import { Input } from "../Input";
import { FormContainer } from "../FormContainer";
import H1 from "../h1";
import Button from "../Button";
import queryString from "query-string";
import { ErrorMessage } from "../errorMessage";
import { Loading } from "../Loading";
import logo from "../logo.png";

function ResetPassword(props) {
  let [password, setPassword] = useState("");
  let [passwordRepeat, setPasswordRepeat] = useState("");
  let params = queryString.parse(props.location.search);
  let [errorMessage, setErrorMessage] = useState(null);
  let passRegex = /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,})\S$/;
  const [isLoading, setLoading] = useState(false);

  async function setNewPassword() {
    if (password === passwordRepeat) {
      if (password.match(passRegex)) {
        setLoading(() => true);
        await axiosConfig
          .post("/user/passwordReset", null, {
            params: {
              email: params.email,
              token: params.token,
              password: password,
            },
          })
          .then((res) => {
            setLoading(() => false);
            if (res.status === 200) {
              setErrorMessage(
                "Your password has changed. You can now login using your new password"
              );
              let timer = setTimeout(() => {
                clearTimeout(timer);
                props.history.push("/");
              }, 3000);
            } else {
              setLoading(() => false);
              setErrorMessage(res.data.message);
            }
          })
          .catch((e) => {
            setLoading(() => false);
            setErrorMessage(e.response.data.message);
          });
      } else {
        setErrorMessage(
          "Password does not meet minimum complexity requirements. Passwords must contain at least 8 characters, with at least one uppercase letter, one lowercase, and one number. "
        );
      }
    } else {
      setErrorMessage("Passwords should match");
    }
  }

  return (
    <React.Fragment>
      {isLoading ? <Loading /> : null}
      <FormContainer>
        <img style={{ width: "215px" }} src={logo} alt="logo" />
        <H1>Reset password</H1>
        <Input
          type="password"
          name="password"
          value={password}
          placeholder="Enter your new password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type="password"
          name="passwordRepeat"
          value={passwordRepeat}
          placeholder="Repeat your new password"
          onChange={(e) => setPasswordRepeat(e.target.value)}
        />
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <Button type="button" onClick={setNewPassword}>
          Set new password
        </Button>
      </FormContainer>
    </React.Fragment>
  );
}

export default ResetPassword;
