import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { InfoWrapper, FormFieldWrapper, H1, Select } from "./styles";
import Button from "../Button";

export const SaveButton = styled(Button)`
  width: 150px;
`;

function LabSampleInfo() {
  const [values, setValues] = useState([{}]);
  useEffect(() => {
    console.log(values);
  }, [values]);

  function handleChange(e) {
    const { name, value } = e.target;
    setValues(...values, { name, value });
  }

  return (
    <InfoWrapper>
      <div>
        <H1>Samples</H1>
      </div>
      <div>
        <H1>Questionnaire</H1>
        <p>Completed on 11/22/2019</p>
      </div>
      <FormFieldWrapper>
        <H1>Certification Methodology</H1>
        <label>
          <input
            onChange={handleChange}
            type="checkbox"
            value="nir"
            name="certMethod"
          />
          NIR
        </label>
        <label>
          <input onChange={handleChange} type="checkbox" name="certMethod" />
          WET
        </label>
      </FormFieldWrapper>
      <FormFieldWrapper>
        <H1>Exemptions</H1>
        <label>
          <input onChange={handleChange} type="checkbox" name="exemption" />
          Corn Stage Exempt
        </label>
        <label>
          <input onChange={handleChange} type="checkbox" name="exemption" />
          Shipping Fee
        </label>
        <label>
          <input onChange={handleChange} type="checkbox" name="exemption" />
          Express Shippinh
        </label>
      </FormFieldWrapper>
      <Select>
        Status
        <option value="survey">Survey</option>
        <option value="payment">Payment not received</option>
      </Select>
      <SaveButton>Save</SaveButton>
    </InfoWrapper>
  );
}

export default LabSampleInfo;
