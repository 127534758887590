import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

export const QuestionnaireWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  height: 120%;
  background-color: white;
  color: #666;
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  font-weight: 400;
  & > h1 {
    font-size: 16px;
    font-weight: bold;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 15px;
  & > h1 {
    font-size: 24px;
    margin: 0;
    margin-bottom: 8px;
  }
  & > h3 {
    font-size: 16px;
    margin: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  justify-items: start;
  align-items: end;
  grid-auto-flow: row;
  grid-gap: 15px;

  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 90%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-row: 1 / span 5;

  background-color: white;
  font-weight: 400;
  font-size: 12px;
  & > p:nth-child(2n) {
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 8px;
  }
  height: 100%;
`;

export const Container = styled.div`
  display: grid;
  justify-items: stretch;
  align-items: start;
  width: 100%;
  grid-template-columns: 1fr 4fr;
`;

export const P = styled.p`
  font-size: 12px;
  font-weight: normal;
  color: #666666;
  margin: 0;
  & > :nth-child(2n) {
    font-weight: bold;
  }
`;

export const Label = styled.label`
  font-size: 16px;
  margin-bottom: 16px;
  margin-right: 4px;
  margin-left: 8px;
  & * {
    padding: 2rem;
  }
`;

export const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: auto;
  background-color: lavender;
  padding-left: 20px;
  margin-bottom: 20px;
`;

export const QuestionTitle = styled.p`
  font-family: "ProximaNova-Bold";
  font-size: 16px;
  font-weight: 900;
  margin: 0;
  padding-top: 10px;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  width: 400px;
  margin: auto;
  margin-bottom: 20px;

  margin-top: 25px;
  & > input {
    padding: 0;
    margin: 0;
    margin: auto;
    padding: 23px 10px;
  }
`;

export const Error = styled.span`
  margin-top: 12px;
  margin-bottom: 12px;
  color: red;
`;

export const StyledCheckbox = withStyles({
  label: {
    fontFamily: "ProximaNova"
  }
})(FormControlLabel);
