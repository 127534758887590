import React from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const ActiveLabs = (props) => {
  return (
    <ExcelFile element={<>Active Labs</>}>
      <ExcelSheet data={props.dataset} name="Active Labs">
        <ExcelColumn label="Lab Name" value="companyName" />
        <ExcelColumn label="Certification Method" value="certificationMethod" />
        <ExcelColumn label="Contact Name" value="contact1" />
        <ExcelColumn label="Address" value="address1" />
        <ExcelColumn label="City" value="city" />
        <ExcelColumn label="State" value="state" />
        <ExcelColumn label="Zip (Postal Code)" value="zip" />
        <ExcelColumn label="Country" value="country" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Phone" value="telephone" />
        <ExcelColumn label="CS Exempt (WET)" value="cornSilageExemptWET" />
        <ExcelColumn label="CS Exempt (NIRS)" value="cornSilageExemptNIRS" />
        <ExcelColumn label="Express Shipping" value="expressShipping" />
      </ExcelSheet>
    </ExcelFile>
  );
};
export const InactiveLabs = (props) => {
  return (
    <ExcelFile element={<>Inactive Labs</>}>
      <ExcelSheet data={props.dataset} name="Inactive Labs">
        <ExcelColumn label="Lab Name" value="companyName" />
        <ExcelColumn label="Certification Method" value="certificationMethod" />
        <ExcelColumn label="Contact Name" value="contact1" />
        <ExcelColumn label="Address" value="address1" />
        <ExcelColumn label="City" value="city" />
        <ExcelColumn label="State" value="state" />
        <ExcelColumn label="Zip (Postal Code)" value="zip" />
        <ExcelColumn label="Country" value="country" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Phone" value="telephone" />
        <ExcelColumn label="CS Exempt (WET)" value="cornSilageExemptWET" />
        <ExcelColumn label="CS Exempt (NIRS)" value="cornSilageExemptNIRS" />
        <ExcelColumn label="Express Shipping" value="expressShipping" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const AllLabs = (props) => {
  return (
    <ExcelFile element={<>All Labs</>}>
      <ExcelSheet data={props.dataset} name="All Labs">
        <ExcelColumn label="Lab Name" value="companyName" />
        <ExcelColumn label="Certification Method" value="certificationMethod" />
        <ExcelColumn label="Contact Name" value="contact1" />
        <ExcelColumn label="Address" value="address1" />
        <ExcelColumn label="City" value="city" />
        <ExcelColumn label="State" value="state" />
        <ExcelColumn label="Zip (Postal Code)" value="zip" />
        <ExcelColumn label="Country" value="country" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn label="Phone" value="telephone" />
        <ExcelColumn label="CS Exempt (WET)" value="cornSilageExemptWET" />
        <ExcelColumn label="CS Exempt (NIRS)" value="cornSilageExemptNIRS" />
        <ExcelColumn label="Express Shipping" value="expressShipping" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export const LabsWithSubmissions = (props) => (
  <ExcelFile element={<>Labs With Submission </>}>
    <ExcelSheet data={props.dataset} name="Labs With Submissions">
      <ExcelColumn label="Lab ID" value="labID" />
      <ExcelColumn label="Lab Name" value="companyName" />
      <ExcelColumn label="Certification Method" value="certificationMethod" />
      <ExcelColumn label="Contact Name" value="contact1" />
      <ExcelColumn label="Address" value="address1" />
      <ExcelColumn label="City" value="city" />
      <ExcelColumn label="State" value="state" />
      <ExcelColumn label="Zip (Postal Code)" value="zip" />
      <ExcelColumn label="Country" value="country" />
      <ExcelColumn label="Email" value="email" />
      <ExcelColumn label="Phone" value="telephone" />
      <ExcelColumn label="CS Exempt (WET)" value="cornSilageExemptWET" />
      <ExcelColumn label="CS Exempt (NIRS)" value="cornSilageExemptNIRS" />
      <ExcelColumn label="Express Shipping" value="expressShipping" />
      {props.columns.map((sample) => (
        <ExcelColumn
          key={sample.id}
          label={sample.name}
          value={(col) => col[sample.id]}
        />
      ))}
    </ExcelSheet>
  </ExcelFile>
);
