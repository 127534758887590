import styled from "styled-components";

export const SampleInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 90px;
  margin: 0;
  padding: 0;
  height: 400px;

  & > * {
    margin: 0;
    margin-bottom: 0.5em;
  }

  & > p {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`;

export const Submittions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 0;

  width: 100%;
  margin-left: 2em;

  background-color: #f6f6f6;

  & > h3,
  p {
    margin: 0;
    padding: 0;
    margin-left: 1rem;
    margin-top: 1em;
  }

  & > button {
    width: 400px;
    margin: auto;
  }
`;

export const Analyte = styled.div`
  width: 100%;
  display: table;
  margin: auto;
  padding: 10px;
  &:first {
    margin-top: 20px;
  }
  padding: 0;
  overflow: auto;

  & > h6 {
    margin-left: 1rem;
    font-size: 14px;
    float: left;
    width: 140px;
  }
`;

export const H1 = styled.h1`
  color: #666;
  font-size: 24px;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100px;
  height: 30px;
  border: 1px solid #383789;
  border-radius: 8px;
  vertical-align: middle;
`;
