import React, { useEffect, useState } from "react";
import { axiosConfig } from "../../utils/axiosConfig";

import { InfoWrapper, FormFieldWrapper, H2 } from "./styles";
import { SaveButton } from "./labSampleInfo";

import { Loading } from "../Loading";

function LabSampleForm(props) {
  const [loading, setLoading] = useState(false);
  const [certMethod, setSert] = useState([props.lab.certificationMethod])

  useEffect(() => {
    let certCopy = [props.lab.certificationMethod];
    if (props.lab.certificationMethod === "BOTH") {
      certCopy.splice(certMethod.indexOf("BOTH"), 1);
      certCopy.push("WET");
      certCopy.push("NIRS");
    }
    setSert(certCopy);
  }, [props]);

  function handleCertMethod(e) {
    const { value, checked } = e.target;
    let certCopy = certMethod;
    if (certMethod.includes("BOTH")) {
      certCopy.splice(certMethod.indexOf("BOTH"), 1);
      certCopy.push(value);
    } else if (!checked) {
      if (!certMethod.indexOf(value) !== -1) {
        certCopy.splice(certMethod.indexOf(value), 1);
      }
    } else if (checked) {
      certCopy.push(value);
    } else if (certMethod.length > 1) {
      certCopy.push(value);
      setSert("BOTH");
      props.lab.certificationMethod = "BOTH";
    }
    props.lab.certificationMethod = certMethod;

    // if (
    //   (add.certificationMethod === "BOTH" ||
    //     props.lab.certificationMethod === "BOTH") &&
    //   checked === false
    // ) {
    //   add.certificationMethod = value;
    //   props.lab.certificationMethod = value;
    // } else if (
    //   (add.certificationMethod === "NIRS" ||
    //     add.certificationMethod === "WET") &&
    //   checked === true
    // ) {
    //   add.certificationMethod = "WETNIRS";
    //   props.lab.certificationMethod = "WETNIRS";
    // }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let body = (({
      expressShipping,
      shippingFee,
      cornSilageExemptWET,
      cornSilageExemptNIRS,
      certificationMethod,
      allowResubmission
    }) => ({
      expressShipping,
      shippingFee,
      allowResubmission,
      cornSilageExemptWET,
      cornSilageExemptNIRS,
      certificationMethod
    }))(props.lab);

    if (body.certificationMethod.length > 1) {
      body.certificationMethod = "BOTH";
    }
    body.certificationMethod = body.certificationMethod.toString();
    setLoading(() => true);
    await axiosConfig
      .post(`/lab/${props.lab.labID}/extra`, body)
      .then(res => {
        if (res.status === 200) {
          setLoading(() => false);
        }
      })
      .catch(e => {
        setLoading(() => false);
        console.error(e);
      });
  }

  function handleChange(e) {
    const { name, checked } = e.target;
    props.lab[name] = checked;
  }

  return (
    <InfoWrapper>
      {loading ? <Loading /> : null}
      <div>
        <H2>Samples</H2>
      </div>
      <div>
        <H2>Questionnaire</H2>
        <p>Completed on 11/22/2019</p>
      </div>
      <form>
        <FormFieldWrapper>
          <H2>Certification Method</H2>
          <label>
            <input
              type="checkbox"
              name="certificationMethod"
              value="NIRS"
              key={certMethod}
              defaultChecked={
                certMethod.includes("BOTH") || certMethod.includes("NIRS")
              }
              onChange={handleCertMethod}
            />
            NIRS
          </label>
          <label>
            <input
              type="checkbox"
              name="certificationMethod"
              value="WET"
              key={certMethod}
              defaultChecked={
                certMethod.includes("BOTH") || certMethod.includes("WET")
              }
              onChange={handleCertMethod}
            />
            WET
          </label>
        </FormFieldWrapper>
        <FormFieldWrapper>
          <H2>Exemptions</H2>
          <label>
            <input
              type="checkbox"
              name="cornSilageExemptWET"
              defaultChecked={props.lab.cornSilageExemptWET}
              onChange={handleChange}
            />
            Corn Silage Exempt WET
          </label>
          <label>
            <input
              type="checkbox"
              name="cornSilageExemptNIRS"
              defaultChecked={props.lab.cornSilageExemptNIRS}
              onChange={handleChange}
            />
            Corn Silage Exempt NIRS
          </label>
          <label>
            <input
              type="checkbox"
              name="shippingFee"
              defaultChecked={props.lab.shippingFee}
              onChange={handleChange}
            />
            Shipping Fee
          </label>
          <label>
            <input
              type="checkbox"
              name="expressShipping"
              defaultChecked={props.lab.expressShipping}
              onChange={handleChange}
            />
            Express Shipping
          </label>
          <label>
            <input
              type="checkbox"
              name="allowResubmission"
              defaultChecked={props.lab.allowResubmission}
              onChange={handleChange}
            />
            Allow Resubmission
          </label>
        </FormFieldWrapper>
        <SaveButton type="submit" onClick={handleSubmit}>
          Save
        </SaveButton>
      </form>
    </InfoWrapper>
  );
}

export default LabSampleForm;
