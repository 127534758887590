import React, { useEffect } from "react";
import Header from "../header";
import AgreementContent from "./agreementContent";
import { axiosConfig } from "../../utils/axiosConfig.js";
import styled from "styled-components";

const AgreementWrapper = styled.div`
  width: 100%;
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 0;
`;

function Agreement() {
  async function getLab() {
    await axiosConfig.get("/lab").then(res => {
      window.localStorage.setItem("loginData", JSON.stringify(res.data));
    });
  }
  useEffect(() => {
    getLab();
  }, []);
  return (
    <AgreementWrapper>
      <Header />
      <AgreementContent />
    </AgreementWrapper>
  );
}

export default Agreement;
