import React, { useState, useRef, useEffect } from "react";
import logo from "../small-logo.png";
import styled from "styled-components";
import { Div, SmallLogo, LabName, Ul, Li, H1 } from "./styles";
import { withRouter } from "react-router-dom";
import arrow from "../../arrow.svg";
import { Arrow } from "./styles";
import { axiosConfig } from "../../utils/axiosConfig";

import { StyledLink } from "../StyledLink";

const AccordionContent = styled.div`
  background-color: none;
  overflow: hidden;
  transition: max-height 0.6s ease;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  a {
    margin-top: 10px;
  }
`;

function AdminHeader(props) {
  const [active, setActive] = useState(false);
  const contentRef = useRef(null);

  async function logout() {
    axiosConfig
      .post("/user/logout")
      .then((res) => {
        if (res.status === 200) {
          window.localStorage.removeItem("loginData");
          props.history.push("/");
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
  }, [contentRef, active]);

  const toogleActive = () => {
    setActive(!active);
  };

  function goHome() {
    props.history.push("/");
  }

  return (
    <Div>
      <SmallLogo onClick={goHome} src={logo} alt="logo" />
      <div>
        <Ul>
          <Li>
            <StyledLink to="/laboratories">Laboratories</StyledLink>
          </Li>
          <Li>
            <StyledLink to="/adminsamples">Samples</StyledLink>
          </Li>
          <Li>
            <StyledLink to="/reports">Reports</StyledLink>
          </Li>
          <Li>
            <StyledLink to="/analytes">Analytes</StyledLink>
          </Li>
          <Li>
            <StyledLink to="/settings">Settings</StyledLink>
          </Li>
          <LabName
            className={active ? "accordion-icon rotate" : "accordion-icon"}
            onClick={toogleActive}
          >
            <H1>
              {props.companyName}
              <Arrow src={arrow} alt="arrow" />
            </H1>
            <AccordionContent ref={contentRef} className="accordion-content">
              <StyledLink onClick={logout} to="/">
                Logout
              </StyledLink>
            </AccordionContent>
          </LabName>
        </Ul>
      </div>
    </Div>
  );
}

export default withRouter(AdminHeader);
