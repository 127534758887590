import styled from "styled-components";

export const ErrorMessage = styled.span`
  margin: 0;
  padding: 0;
  width: 80%;
  text-align: center;
  color: #bb0528;
  font-size: 20px;
  // margin-bottom: 1rem;
`;
