import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { axiosConfig } from "../../utils/axiosConfig.js";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#383789",
    },
  },
});

const columns = [
  {
    title: "Analyte name",
    field: "analyte",
  },
  { title: "Required for certification?", field: "certificationAnalyte" },
  { title: "Abbreviation", field: "abbr" },
];

function AnalytesTable() {
  const [analytes, setAnalytes] = useState([]);
  useEffect(() => {
    async function getAnalytes() {
      await axiosConfig.get("/analyte/all").then((res) => {
        if (res.status === 200) {
          setAnalytes(res.data);
        }
      });
    }
    getAnalytes();
  }, []);

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          title="Analytes"
          columns={columns}
          isLoading={analytes.length === 0}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30],
            actionsColumnIndex: -1,
          }}
          style={{
            maxWidth: "80%",
            margin: "auto",
          }}
          data={Array.from(analytes)}
          localization={{
            body: {
              editRow: {
                deleteText: "Are you sure you want to delete analyte?",
                saveText: "Save analyte changes",
              },
            },
          }}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  const { sampleID, ...rest } = newData;
                  const data = Array.from(analytes);
                  data.push(newData);
                  analytes.push(newData);
                  setAnalytes(() => data);
                  setAnalytes(data);
                  //   addRow(rest);
                }, 600);
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  const { sampleID, ...rest } = newData;
                  const data = Array.from(analytes);
                  data[data.indexOf(oldData)] = newData;
                  setAnalytes(() => data);
                  setAnalytes(data);

                  //   updateRow(sampleID, rest);
                  resolve();
                }, 700);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  const data = Array.from(analytes);
                  data.splice(oldData.tableData.id, 1);
                  setAnalytes(() => data);
                  // setTableData(data);
                  //   deleteRow(oldData.sampleID);
                }, 600);
              }),
          }}
        />
      </MuiThemeProvider>
    </>
  );
}

export default AnalytesTable;
