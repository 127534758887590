import styled from "styled-components";
import { Div } from "../Div";
import { Input } from "../Input";
import Button from "../Button";

export const Void = styled.div`
  display: none;
`;

export const customStyles = {
  width: "62px"
};

export const H2 = styled.h2`
  font-size: 24px;
  color: #666;
  font-family: "ProximaNova";
  height: 29px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const LabType = styled.div`
  font-size: 20px;
  letter-spacing: 0;
  line-height: normal;

  & > sub {
    margin-top: 0;
    font-size: 12px;
  }
`;

export const CheckboxContainer = styled.div`
  width: 100%;
`;

export const FieldWrapper = styled(Div)`
  & > input,
  & > input:active,
  & > input:focus {
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    margin-bottom: 20px;
    text-decoration: none;
    padding: 23px 10px;
    box-sizing: border-box;
  }
  &::placeholder {
    color: #c4c4c4;
  }
`;

export const InputField = styled(Input)`
  &[type="checkbox"] {
    margin: 10px;
    margin-left: 0;
    background-color: white;
    border-radius: 8px;
    vertical-align: middle;
    border: 1px solid #ddd;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    height: 24px;
    padding: 0;
    width: 24px;
    &:checked {
      background-color: gray;
    }
    & :last-child {
      margin-bottom: 10px;
    }
  }
`;

export const VerificationWrapper = styled.div`
  display:flex;
  flex-direction: column;
  width: 500px;
  height: 100vh;
  align-items: center;
  justify-content: center
  box-sizing: border-box;
  margin: 0 auto;
`;

export const VericiationNumberInput = styled(Input)`
  width: 100%;
  padding: 23px 10px;
`;

export const VerifyButton = styled(Button)`
  width: 100%;
  font-size: 24px;
`;
